import React, {useRef} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add'
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Tooltip from "@material-ui/core/Tooltip";
import {months} from "../../../../constants";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {

        marginTop: theme.spacing(2),
    },
    select: {
        position: "sticky",

    },
    spacer: {
        display: 'flex',
        justifyContent: "space-between",

    },
    buttonGroup: {
        marginRight: 10
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));


export default function SelectTable(props) {
    const {
        restrictions,
        handleYearChange,
        handleMandantChange,
        handleTableChange,
        mandant,
        year,
        yearItems,
        table,
        tableItems,
        downloadCSV,
        downloadExcel,
        handleTableAdd,
        admin,
        tableType,
        month,
        uploadCsv,
        kreditor,
        kreditorItems,
        kreditorSelected,
        handleKreditorChange
    } = props
    const classes = useStyles()
    const inputFile = useRef(null)

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    return (
        <div className={classes.spacer} id={'SelectTable'}>
            <div className={classes.select}>
                <FormControl className={classes.formControl}>
                    <InputLabel id={'Mandant'}>Mandant</InputLabel>
                    <Select value={mandant}
                            onChange={(event) => handleMandantChange(event.target.value)}>

                        {restrictions.map((x, index) => <MenuItem value={index}>
                            {x.mandanten}
                        </MenuItem>)}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id={'Jahr'}>Jahr</InputLabel>
                    <Select value={year}
                            onChange={(event) => handleYearChange(event.target.value)}>
                        {yearItems.map(x => (<MenuItem value={x}>
                            {x}
                        </MenuItem>))}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id={'Tabelle'}>Tabelle</InputLabel>
                    <Select value={table}
                            onChange={(event) => handleTableChange(event.target.value, 'table')}>
                        {
                            tableItems.map((x, index) => (<MenuItem value={index}>
                                {x.tab}
                            </MenuItem>))}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>

                {['wohngeld','wirtschaftsplan'].includes((tableItems.find((x,i) => i  === table) || {}).type) &&
                <FormControl className={classes.formControl}>
                    <InputLabel id={'kreditor'}>Kreditor</InputLabel>
                    <Select value={kreditor}
                            onChange={(event) => handleKreditorChange(event.target.value)}>
                        {
                            kreditorItems.map((x,index) => (<MenuItem  key={index} value={index}>
                                {x.kreditor}
                            </MenuItem>))}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                }

                {(tableType === 'inven') &&
                <FormControl className={classes.formControl}>
                    <InputLabel id={'Months'}>Monat</InputLabel>
                    <Select value={month}
                            onChange={(event) => handleTableChange(event.target.value, 'months', event.target.value.type, event.target.value.id)}>
                        {
                            months.map((x,index) => (<MenuItem  key={index} value={index}>
                                {x.name}
                            </MenuItem>))}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                }
                {admin &&
                <Tooltip title="Neue Tabelle">
                    <IconButton className={classes.button} onClick={handleTableAdd}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                }


            </div>
            {['rueck', 'arap', 'prap'].includes((tableItems.find((x,i) => i  === table) || {}).type) &&
            <div className={classes.buttonGroup}>
                <Tooltip
                    title="Download Excel">
                    <IconButton onClick={downloadExcel} className={classes.button}>
                        <ViewComfyIcon/>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Download CSV">
                    <IconButton onClick={downloadCSV} className={classes.button}>
                        <CloudDownloadIcon/>
                    </IconButton>
                </Tooltip>
            </div>}
            {['zins', 'kreditor', 'debitor'].includes((tableItems.find((x,i) => i  === table) || {}).type) &&
            <div className={classes.buttonGroup}>
                <Tooltip
                    title="Download Excel">
                    <IconButton onClick={downloadExcel} className={classes.button}>
                        <ViewComfyIcon/>
                    </IconButton>
                </Tooltip>
            </div>}
            {['wohngeld','wirtschaftsplan'].includes((tableItems.find((x,i) => i === table) || {}).type) && kreditorSelected &&
            <div className={classes.buttonGroup}>
                <Tooltip
                    title="Upload CSV">

                    <IconButton  onClick={onButtonClick} className={classes.button}>
                        <CloudUploadIcon/>
                        <input accept=".csv,.xlsx,.xls"
                               type='file' id='file'
                               ref={inputFile}
                               onChange={uploadCsv}
                               style={{display: 'none'}}
                        />
                    </IconButton>

                </Tooltip>
            </div>}
        </div>
    )
}