import React, {useState} from 'react';
import {makeStyles,} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../DrawerComponent/AppBar'
import SideBar from "../DrawerComponent/SideBar";
import Content from "../DrawerComponent/pages/Tables/content";
import {Route, Switch} from "react-router-dom";
import Restriction from "../DrawerComponent/pages/Restrictions/Restriction";
import auth from "../../auth";
import authAdmin from "../../authAdmin";
import RenameTable from "../DrawerComponent/pages/MandantUpdate/renameTable"
import Settings from "../DrawerComponent/pages/Settings/Settings";
import Qr from "../DrawerComponent/pages/QR/QR"

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start'

    },
    content: {
        padding: theme.spacing(8, 0),
        position: "absolut",
        height: '100%',
        width: '100%'


    },
}));


function MiniDrawer(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {text, sideBar} = props

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
            <div className={classes.root}>
                <CssBaseline />
                <Header text={text}
                    handleDrawerOpen = {handleDrawerOpen}
                    open={open}
                />

                <SideBar handleDrawerClose={handleDrawerClose}
                     open={open}
                     drawerWidth ={drawerWidth}
                     sideBar = {sideBar}
                />
                <div className={classes.content}>
                    <Switch>
                        <Route path='/settings' exact component={auth(Settings)}/>
                        <Route path='/qr' exact component={auth(Qr)}/>
                        <Route path='/restrictions' component={authAdmin(auth(Restriction))}/>
                        <Route path='/renameTable' component={authAdmin(auth(RenameTable))}/>
                        <Route component={auth(Content)}/>
                    </Switch>
                </div>

            </div>
    );
}




const test = React.memo(MiniDrawer)

export default test