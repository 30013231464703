import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    addButton: {
        position: 'absolute',
        bottom: 10,
        left: 70,
    },
    button: {
        width: 40,
        height: 40,
    },
    tooltip: {
        zIndex: 101
    }
}))

export default function AddButton(props) {
    const {write, addOpen} = props
    const classes = useStyles()
    if (write) {
        return (
            <Tooltip title="Zeile hinzufügen" className={classes.tooltip}>
                <IconButton aria-label="add" className={classes.addButton} onClick={addOpen}>
                    <AddCircleIcon className={classes.button}/>
                </IconButton>
            </Tooltip>
        )
    } else {
        return null
    }
}

