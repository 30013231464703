import React, {Component, Fragment} from "react";
import TableInventur from "./Table"
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';
import AddButton from "../AddButton";
import AddData from "./AddData";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import {columnsInventur} from "../../../../../constants";
import AddIcon from '@material-ui/icons/Add'
import TextField from "@material-ui/core/TextField";
import SaveChanges from "../../Forms/SaveChanges";
import DeleteDialog from "../../Forms/Delete";

//TODO Inventur ändern wie gewünscht
//TODO das ganze geraffel auf Mariannes Server packen.

const useStyles = theme => ({
    tab: {
        position: 'absolute',
        bottom: 0,
    },
    editButton: {
        position: 'absolute',
        bottom: 10,
        left: 110,
    }
})

function mapToEmptyString() {
    const unloadData = []
    columnsInventur.forEach((x) => unloadData[x.key] = '')
    return unloadData
}

function mapToEmptyArray() {
    const unloadData = []
    columnsInventur.forEach((x) => unloadData[x.key] = [])
    return unloadData
}

function mapToEmptyBoolean() {
    const unloadData = []
    columnsInventur.forEach((x) => unloadData[x.key] = false)
    return unloadData
}


function filterForCat(data) {
    var dict = {}
    for (let i = 0; i < data.length; i++) {
        dict[data[i]['lagerbeschreibung']] = data[i]['json_agg']
    }
    return dict
}

function getOpenCat(categories, open) {
    const result = {}
    Object.keys(categories).forEach((key) => result[key] = open)
    return result
}


class Inventur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataToAdd: mapToEmptyString(),
            toUpdate: {},
            saveChanges: false,
            selectedSheet: 0,
            categories: [],
            changeData: false,
            categorieOpen: {},
            edit: {},
            newSheetName: '',
            press: false,
            open: false,
            loadingData: mapToEmptyBoolean(),
            options: mapToEmptyArray(),
            sheets: [],
            deleteOpen:false,
            idToDelete:''
        }
    }

    getData = () => {
        const body = {
            mandanten_id: this.props.mandant,
            year: this.props.year,
            month: this.props.month,
            sheets: false,
            table: this.state.sheets[this.state.selectedSheet]
        }
        fetch('/api/getInventur', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((res) => res.json()).then((res) => this.setState((prevState) => {
            return {
                ...prevState,
                data: filterForCat(res),
                categorieOpen: getOpenCat(filterForCat(res), true),
                edit: getOpenCat(filterForCat(res), false)
            }
        }))
    }

    getSheetsAndData = () => {
        const body = {
            mandanten_id: this.props.mandant,
            year: this.props.year,
            month: this.props.month,
            sheets: true
        }
        fetch('/api/getInventur', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((res) => res.json()).then((res) => this.setState((prevState) => {
            return {...prevState, sheets: [...res.map((x) => x.tabelle)], selectedSheet: 0}
        }, () =>  {
            if ([...res.map((x) => x.tabelle)].length !== 0) {
                this.getData()
            } else {
                this.setState({...this.state, data: []})
            }
        }))
    }

    componentDidMount() {
        this.getSheetsAndData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.month !== prevProps.month || this.props.year !== prevProps.year) {
            if (Object.keys(this.state.edit).filter(x => this.state.edit[x])[0] && this.state.changeData) {
                const toSave = Object.keys(this.state.edit).filter(x => this.state.edit[x])
                this.handleEditSave(toSave[0], prevProps.month, prevProps.year)
            }
            this.getSheetsAndData()
        }
    }

    changeTabs = (newValue) => {
        this.setState({...this.state, selectedSheet: newValue}, () =>
            {this.getData()}
        )
    }


    handleChange = (event, newValue) => {
        if (Object.keys(this.state.edit).filter(x => this.state.edit[x])[0]) {
            this.setState({...this.state, saveChanges: true, newValue: newValue})
        } else {
            this.changeTabs(newValue)
        }

    }

    handleSave = (yesNo) => {
        this.setState({...this.state, saveChanges: false}, () => {
            if (yesNo === 'save') {
                const toSave = Object.keys(this.state.edit).filter(x => this.state.edit[x])
                this.handleEditSave(toSave[0])
                this.changeTabs(this.state.newValue)
            } else if (yesNo === 'discardChanges') {
                this.setState({...this.state, toUpdate: {}, edit: {...getOpenCat(this.state.data)}})
                this.changeTabs(this.state.newValue)
            }
        })
    }

    handleDataAdd = () => {
        fetch('/api/insertInventur', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.dataToAdd)
        }).then((res) => res.json()).then((id) => this.setState((prevState) => {
            const toAdd = prevState.dataToAdd
            const data = prevState.data
            if (data[toAdd.lagerbeschreibung]) {
                data[toAdd.lagerbeschreibung].push({...toAdd, id: id})
            } else {
                data[toAdd.lagerbeschreibung] = [{...toAdd, id: id}]
            }
            return {
                ...prevState,
                data: data,
                dataToAdd: {'mandant': this.props.mandant, 'tabelle': this.state.sheets[this.state.selectedSheet], tab: this.props.tab, type: this.props.type}
            }
        }))
    }

    handleAddEntry = (id, newValue) => {
        this.setState({...this.state, dataToAdd: {...this.state.dataToAdd, [id]: newValue}})
    }


    handleAddOpen = () => {
        this.setState({
            ...this.state,
            open: !this.state.open,
            dataToAdd: {'mandant': this.props.mandant, 'tabelle': this.state.sheets[this.state.selectedSheet], tab: this.props.tab, type: this.props.type}
        })
    }


    handleTextChange = (value, key, id) => {
        this.setState((prevState) => {
                const line = {...prevState.toUpdate}
                line[id] = {...line[id], [key]: value.value}
                return {...prevState, toUpdate: line}
            }
        )
    }

    deleteDialogToggle = (id) => {
        this.setState({...this.state, deleteOpen: !this.state.deleteOpen, idToDelete: id||''})
    }

    handleOpenTree = (cat) => {
        this.setState({
                ...this.state,
                categorieOpen: {...this.state.categorieOpen, [cat]: !this.state.categorieOpen[cat]}
            }
        )
    }

    toggleEdit = (cat, use) => {
        if (Object.keys(this.state.edit).filter(x => this.state.edit[x])[0] && use) {
            const toSave = Object.keys(this.state.edit).filter(x => this.state.edit[x])
            this.handleEditSave(toSave[0])
        }

        this.setState({
                ...this.state,
                categorieOpen: {...this.state.categorieOpen, [cat]: true},
                edit: {...getOpenCat(this.state.data), [cat]: !this.state.edit[cat]}
            }
        )


    }


    handleLoadData = (data) => {
        const options = this.createOptions(data)
        this.setState({
            ...this.state,
            loadingData: {...this.state.loadingData, [data]: false},
            options: {...this.state.options, [data]: options}
        })
    }

    handleEditSave = (cat, month, year) => {
        this.setState((prevState) => {
                const update = prevState.toUpdate
                const toUpdate = prevState.data[cat]
                Object.keys(update).forEach((key) => {
                    const line = toUpdate.findIndex(x => x.id === key)
                    toUpdate[line] = {...toUpdate[line], ...update[key]}
                    update[key] = {'menge': toUpdate[line].menge, 'ekpreis': toUpdate[line].ekpreis}
                })
                return {...prevState, data: {...prevState.data, [cat]: [...toUpdate]}}
            }, () => {
                fetch('/api/updateInventur', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        update: this.state.toUpdate,
                        month:  this.props.month,
                        year: this.props.year,
                        mandanten_id: this.props.mandant
                    })
                }).then(() => this.setState({...this.state, toUpdate: {}})
                )
            }
        )
    }

    createOptions = (field) => {
        let b = []
        if (this.state.dataToAdd['lagerbeschreibung']) {
            b = (this.state.data[this.state.dataToAdd['lagerbeschreibung']] || [{[field]: ''}]).map(x => x[field])
        } else {
            Object.keys(this.state.data).map(x => this.state.data[x].map(x => x[field])).forEach((x) => b.push(...x))
        }
        return Array.from(new Set(b || [''])).filter(x => x)
    }
    handleNewSheet = (event) => {
        this.setState({...this.state, newSheetName: event.target.value})
    }


    enterNewSheet = (event) => {
        if (event.key === 'Enter') {
            this.setState({...this.state, sheets: [...this.state.sheets, this.state.newSheetName], newSheetName: '', press: false})
        }
    }

    toggleNewSheet = () => {
        this.setState({...this.state, press: true})
    }

    handleDelete = () => {
        fetch('/api/deleteInventur', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({
                id: this.state.idToDelete})}).then(() => {this.deleteDialogToggle()})
    }






    render() {
        const {
            classes,

        } = this.props
        const {
            selectedSheet,
            open,
            data,
            sheets,
            categorieOpen,
            edit,
            loadingData,
            options,
            changeData,
            dataToAdd,
            press,
            newSheetName,
            saveChanges,
            deleteOpen,

        } = this.state

        return (
            <Fragment>
                <Paper style={{margin: 'auto'}}>
                    <TableInventur
                        handleDelete={this.deleteDialogToggle}

                        index={selectedSheet}
                        edit={edit}
                        handleTextChange={this.handleTextChange}
                        data={data}
                        overallEdit={changeData}
                        open={categorieOpen}
                        handleOpenTree={this.handleOpenTree}
                        toggleEdit={this.toggleEdit}
                        handleEditSave={this.handleEditSave}
                    />


                    <Tabs
                        style={{width: 1480}}
                        id={'Tabs'}
                        component={Paper}
                        className={classes.tab}
                        value={selectedSheet}
                        onChange={this.handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        {sheets.sort().map((x) => <Tab label={x}/>)}
                        {
                        <Tab onClick={this.toggleNewSheet}
                             icon={(press) ?
                                 <TextField
                                     onChange={this.handleNewSheet}
                                     value={newSheetName}
                                     onKeyDown={this.enterNewSheet}/> :
                                 <AddIcon/>}/>
                        }

                    </Tabs>
                </Paper>



                <AddButton write={true}
                           addOpen={this.handleAddOpen}/>


                <AddData
                    open={open}
                    handleAddOpen={this.handleAddOpen}
                    dataToAdd={dataToAdd}
                    handleDataAddEntry={this.handleAddEntry}
                    handleDataAdd={this.handleDataAdd}
                    options={data}
                    loading={loadingData}
                    loadData={this.handleLoadData}
                    optionsfields={options}
                />

                <DeleteDialog
                    deleteOpen={deleteOpen}
                    handleDelete={this.handleDelete}
                    handleDeleteToggle={this.deleteDialogToggle}
                    typ={'Durch bestätigen wird der Eintrag zukünftig nicht mehr angezeigt. Alle bisherigen Eintragungen zu diesem Artikel bleiben bestehen.'}

                />

                <SaveChanges
                    open={saveChanges}
                    solution={this.handleSave}
                />
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(Inventur)