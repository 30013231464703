import React from 'react';
import PropTypes from 'prop-types';

class NumberFormatWithZeroPlus extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool])
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value;
    }


    render() {

        const color = Number(this.props.value || 0) >= 0.1 ? "red" : "black"

        return <div style={{textAlign: 'right', color: color}}
                    title={this.props.value}>{Number(this.props.value || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})}</div>;
    }
}


class NumberFormatWithZeroMinus extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool])
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value;
    }


    render() {
        const color = Number(this.props.value || 0) <= -0.1 ? "red" : "black"
        return <div style={{textAlign: 'right', color: color}}
                    title={this.props.value}>{Number(this.props.value || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})}</div>;
    }
}

export {NumberFormatWithZeroMinus, NumberFormatWithZeroPlus};