import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {columnsInventur as columns} from "../../../../../constants";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Row from "./Row";
import TableContainer from "@material-ui/core/TableContainer";
import React from "react";
import {uuid} from "uuidv4";

const {memo} = require("react");

function areEqual(prevProps, nextProps) {
    return !(prevProps.data !== nextProps.data || prevProps.open !== nextProps.open || prevProps.edit !== nextProps.edit);
}

function calcWert(data) {
    Object.keys(data).forEach((group) => data[group].forEach((x) => {
        x.pfandwert = String(Math.round(Number(x["menge"] || '') * Number(x["pfand"] || '') * 100 + 0.03) / 100)
        x.wert = String(Math.round(100 * Number(x["menge"] || '') * Number(x["ekpreis"] || '') + 0.03) / 100)
    }))
    return data;
}

function calcCat(data) {
    const result = {}
    Object.keys(data).forEach((group) => result[group] = ['wert', 'pfandwert'].map((x) => String(Math.round(100 * data[group].map(row => row[x]).reduce((a, b) => Number(a || '') + Number(b || ''), 0) + 0.03) / 100)))

    return result
}

function calcOverall(data) {
    const result = {'wert': 0, 'pfandwert': 0}
    if (data) {
        result['wert'] = Object.keys(data).map((x) => data[x][0]).reduce((a, b) => Number(a) + Number(b), 0)
        result['pfandwert'] = Object.keys(data).map((x) => data[x][1]).reduce((a, b) => Number(a) + Number(b), 0)
    }
    return result
}


export default memo(function (props) {
    const {data, edit, handleTextChange, open, handleOpenTree, handleEditSave, toggleEdit, handleDelete} = props
    const calcData = calcWert(data)
    const catOverview = calcCat(calcData)
    const overallWert = calcOverall(catOverview)
    return (
        <TableContainer component={Paper}
                        style={{maxHeight: 'calc(100vh - ' + document.getElementById("AppBar").clientHeight + 'px - ' + (document.getElementById("Tabs") || document.getElementById("AppBar")).clientHeight + 'px - ' + document.getElementById("SelectTable").clientHeight + 'px)'}}>
            <Table stickyHeader size='small' aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {columns.map((x) =>
                            <TableCell
                                style={{maxWidth:(x.key === 'lagerartikel') ? 400 : 120,  minWidth:(x.key === 'lagerartikel') ? 400 : 120}}
                                align={(x.key === 'lagerartikel' || x.key === 'typ') ? "left" : "right"}
                            >
                                {x.name}
                            </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data).sort().map((cat, i) => {
                            return (
                                <Row
                                    openBox={handleOpenTree}
                                    open={open[cat]}
                                    edit={edit[cat]}
                                    toggleEdit={toggleEdit}
                                    key={uuid()}
                                    row={calcData[cat].sort((a, b) => (a.lagerartikel > b.lagerartikel) ? 1 : ((b.lagerartikel > a.lagerartikel) ? -1 : 0))}
                                    catResult={catOverview[cat]}
                                    cat={cat}
                                    handleChange={handleTextChange}
                                    handleEditSave={handleEditSave}
                                    handleDelete={handleDelete}
                                />)
                        }
                    )}
                    <TableRow>
                        <TableCell>

                        </TableCell>
                        <TableCell/>
                        <TableCell component="th" scope="row" width={200}>
                            Insgesamt
                        </TableCell>

                        <TableCell colSpan={4}/>
                        <TableCell align="right">
                            {overallWert["wert"].toLocaleString("de-DE", {minimumFractionDigits: 2})}
                        </TableCell>
                        <TableCell/>
                        <TableCell align="right">
                            {overallWert["pfandwert"].toLocaleString("de-DE", {minimumFractionDigits: 2})}
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )
}, areEqual)
