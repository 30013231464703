import React, {Component, Fragment} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Overview from '../Wohngeld/Overview'
import { kreditorCol, debitorCol } from "./constants"
import AddButton from "../AddButton";
import DeleteDialog from "../../Forms/Delete";


const useStyles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
        position: 'absolute',
        bottom: 0
    }

})

function rowData(data, columns) {
    const obj = {}
    for (const key of columns) {
        obj[key.field] = (data[0]||{[key.field]: ''})[key.field]
    }
    return obj
}
function createEmptyObject(columns) {
    const obj = {}
    for (const key of columns) {
        obj[key.field] = ""
    }

    return obj
}


class Kreditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSheet: 0,
            id: 0,
            toDelete: 0,
            edit: false,
            data: [],
            underEdit: {0: createEmptyObject(kreditorCol)},
            editGesamt: false,
            rowData: createEmptyObject(kreditorCol)
        }
    }


    componentDidMount() {
        fetch('/api/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.props.tableId,
                type: 'kreditor'
            })}).then(res => res.json()).then((data) => this.setState({...this.state, data: data, rowData: rowData(data, kreditorCol)}))
    }
    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.componentDidMount()
        }
    }

    toggleEdit = (id, row) => {
        this.setState({...this.state, underEdit: {...this.state.underEdit, [id]: {...row, selection_id: this.props.tableId}}, id: id, edit: true})
    }


    handleDataDeleteCheck = (id) => {
        this.setState({...this.state, toDelete: id})
    }

    handleDataDelete = () => {
        const toDelete = this.state.toDelete
        fetch('/api/deleteKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id:toDelete,
            })}).then(() =>
            this.setState({...this.state, data: [...this.state.data.filter((item) => item.id !== toDelete)],  toDelete: 0}))
    }

    deleteCancel = () => {
        this.setState({...this.state, toDelete: 0})
    }



    submitChange = (id) => {
        fetch('/api/addChangeKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                selection_id: this.props.tableId,
                data: {...this.state.underEdit[id]},

            })
        }).then((res) => res.json()).then((response) =>
            this.setState((prevState) => {
                const data = prevState.data
                if (id === 0) {
                    data.push({...prevState.underEdit[id], id: response.id})
                } else {
                    const index = data.findIndex((x) => x.id === response.id)
                    data[index] = {...prevState.underEdit[id]}
                }
                return {...prevState, data: data}
            }, () =>
                this.clearChanges(id, 1))
        )
    }


    clearChanges = (id, stillEdit = 0) => {
        this.setState((prevState) => {
            const edit = prevState.underEdit
            if (id === 0) {
                edit[0] = createEmptyObject(kreditorCol)
            } else {
                delete edit[id]
            }
            if (stillEdit){
                return {...prevState, underEdit: {...edit}, id: 0, edit: stillEdit}
            }
            return {...prevState, underEdit: edit, id: 0, edit: stillEdit}
        })
    }

    handleChanges = (newValue, id, field) => {
        this.setState((prevState) => {
            const underEdit = prevState.underEdit
            const row = underEdit[id]
            if (field === 'vortrag') {
                row[field] = newValue
                row['year'] = String(-newValue)
            }
            else {
                row[field] = newValue
            }
            return {...prevState, underEdit: {...underEdit, [id]: {...row}}}
        })
    }


    render() {
        const { data, underEdit, id, toDelete, rowData, edit } = this.state
        const {mandanten, jahr, write, type} = this.props
        return (
            <Fragment>
                <Paper style={{margin: 'auto', height: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}}>
                    <Overview
                        mandanten={mandanten}
                        jahr = {jahr}
			maxHeight={'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}
                        columns={(type === 'kreditor') ? kreditorCol :  debitorCol}
                        thirdSum={1}
                        columnsGesamt={[]}
                        data={data}
                        underEdit={underEdit}
                        id={id}
                        sumWord={'Gesamt'}
                        rowData={rowData}
                        toggleEdit={this.toggleEdit}
                        handleDataDelete={this.handleDataDeleteCheck}
                        submitChange={this.submitChange}
                        clearChanges={this.clearChanges}
                        handleChanges={this.handleChanges}
                        edit={edit}
                    />
                    <AddButton write={write}
                               addOpen={() => this.toggleEdit(0, {})}
                    />
                    <DeleteDialog
                        handleDelete={this.handleDataDelete}
                        handleDeleteToggle={this.deleteCancel}
                        typ={'Wollen Sie diesen Eintrag wirklich unwiederuflich löschen?'}
                        deleteOpen={toDelete}
                    />

                </Paper>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(Kreditor)
