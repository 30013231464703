import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    borders: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
    },
    bordersBottom: {
        borderBottom: '1px solid black'
    },
    bordersNotRight: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black'
    },
    bordersAll: {
        border: '1px solid black'
    },
    bordersNotRightAll: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black'
    },
}))


export default function Row(props) {
    const {flaeche1,
        kurz1,
        flaeche1GesamtName,
        flaeche1TeilName,
        flaeche1Gesamt,
        flaeche1Teil,
        flaeche2,
        kurz2,
        flaeche2GesamtName,
        flaeche2TeilName,
        flaeche2Gesamt,
        flaeche2Teil,
        borderBottom} = props
    const classes = useStyles();
    return (
        <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersNotRightAll : classes.bordersNotRight}>
                <Paper square={true} elevation={0}>
                    {flaeche1}
                </Paper>
                <Paper square={true} elevation={0}>
                    {kurz1}
                </Paper>
            </Grid>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersNotRightAll : classes.bordersNotRight}>
                <Paper square={true} elevation={0} className={classes.bordersBottom}>
                    {flaeche1GesamtName}
                </Paper>
                <Paper square={true} elevation={0}>
                    {flaeche1TeilName}
                </Paper>
            </Grid>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersNotRightAll : classes.bordersNotRight}>
                <Paper square={true} elevation={0} className={classes.bordersBottom}>
                    {flaeche1Gesamt}
                </Paper>
                <Paper square={true} elevation={0}>
                    {flaeche1Teil}
                </Paper>
            </Grid>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersNotRightAll : classes.bordersNotRight}>
                <Paper square={true} elevation={0}>
                    {flaeche2}
                </Paper>
                <Paper square={true} elevation={0}>
                    {kurz2}
                </Paper>
            </Grid>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersNotRightAll : classes.bordersNotRight}>
                <Paper square={true} elevation={0} className={classes.bordersBottom}>
                    {flaeche2GesamtName}
                </Paper>
                <Paper square={true} elevation={0}>
                    {flaeche2TeilName}
                </Paper>
            </Grid>
            <Grid item xs={1} alignItems="center" className={(borderBottom) ? classes.bordersAll : classes.borders}>
                <Paper square={true} elevation={0} className={classes.bordersBottom}>
                    {flaeche2Gesamt}

                </Paper>
                <Paper square={true} elevation={0}>
                    {flaeche2Teil}
                </Paper>
            </Grid>
        </Grid>
    )
}
