import React from 'react';
import PropTypes from 'prop-types';

class NumberFormat extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool])
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value;
    }

    render() {
        if (this.props.value) {
            return <div style={{textAlign: 'right'}}
                        title={this.props.value}>{Number(this.props.value || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})}</div>;
        }
        return <div style={{textAlign: 'right'}}
                    title={this.props.value}>{this.props.value}</div>;

    }
}

export default NumberFormat;