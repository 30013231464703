import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import React, {Fragment} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete"


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));



export default function EditDialog(props) {
    const classes = useStyles()
    const {params, edit, row, id, handleSubmit, handleClear, handleChange, year, autoComplete, autoCompleteValue, handleValueChange, gesamt, part} = props
    return (
        <Dialog open={edit} maxWidth={'lg'} onKeyPress={(event) => {
            if (event.key === 'Enter') {
                handleSubmit(id)
            }
        }}>
            <DialogTitle>
                Neue Daten eingeben.
            </DialogTitle>
            <DialogContent className={classes.root}>
                {params.map((entry) =>
                    (!entry.notEditable) &&
                    ((!entry.valueGetter) ?
                        (entry.autoComplete) ?
                                <Autocomplete
                                    inputValue={row[entry.field]}
                                    value={autoCompleteValue[entry.field]}
                                    freeSolo

                                    options={autoComplete||[{[entry.field]: "", [entry.secondField]:""}]}
                                    getOptionLabel={(option) => String(option[entry.field])}
                                    renderOption={(option) => (
                                        <Fragment>
                                            {option[entry.field]} {option[entry.secondField]}
                                        </Fragment>
                                    )}
                                    loading={autoComplete.length === 0}
                                    onInputChange={(event, newValue) => handleChange(newValue, id, entry.field, entry.autoComplete)}
                                    onChange={(event, newValue) => handleValueChange(newValue, id, entry.field)}
                                    renderInput={(params) => <TextField {...params} label={entry.headerName(year)} />}

                                />
                            :
                            (entry.type === 'number') ?
                                <NumberFormat
                                    {...props}
                                    onValueChange={(value) => handleChange(value.value, id, entry.field)}
                                    value={row[entry.field]||''}
                                    isNumericString={true}
                                    customInput={TextField}
                                    label={entry.headerName(year)}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                /> :
                                <TextField
                                    value={row[entry.field]}
                                    label={entry.headerName(year)}
                                    onChange={(event) => handleChange(event.target.value, id, entry.field)}
                                />
                            :
                        (!entry.valueComplexGetter)?
                        <TextField
                            value={entry.valueFormatter(entry.valueGetter(row))}
                            label={entry.headerName(year)}
                            disabled />
                            :
                            <TextField
                                value={entry.valueFormatter(entry.valueComplexGetter(row, gesamt, part))}
                                label={entry.headerName(year)}
                                disabled />)

                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleClear(id)}>
                    Abbrechen
                </Button>
                <Button color="primary" onClick={() => handleSubmit(id)}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
}