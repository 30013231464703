import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";


export default function (props) {
    const {open, users, checked, handleToggleCheckbox, handleToggle, handleCommit} = props
    return (<div>
        <Dialog open={open} onClose={handleToggle}>
            <DialogTitle>
                Beschränkungen
            </DialogTitle>
            <DialogContent>
                Nachfolgenden Usern die Freigabe für die Tabelle geben.
                <br/>
                <List>
                    {users.map(x =>
                        <ListItem onClick={handleToggleCheckbox(x.id)} button>
                            <ListItemText id={x.id} primary={x.surename + ' ' + x.lastname + ' - ' + x.company}/>
                            <Checkbox
                                edge="end"
                                checked={checked.includes(x.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': `checkbox-list-label-${x.id}`}}
                            />
                        </ListItem>)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleCommit}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
}