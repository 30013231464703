import React, {Component} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from './components/Drawer.js';


import TableChartIcon from '@material-ui/icons/TableChart';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FilterListIcon from '@material-ui/icons/FilterList';
import CropFreeIcon from '@material-ui/icons/CropFree';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appBarText: 'Home',
            admin: false,
            write: false,
            sidebar: [[]],
            errorText: '',
            openError: false
        };
    }

    componentDidMount() {
        fetch('/checkAdmin').then(res => {
            if (res.status === 200) {
                this.setState({ ...this.state, admin: true, sidebar: [['User Beschränkung', "restrictions", <SupervisorAccountIcon/>], ['Tabellen', '', <TableChartIcon/>], ['Tabellen Bennenung', 'renameTable', <FilterListIcon/>], ['Einstellungen', 'settings', <SettingsIcon/>], ['QR Code', 'qr', <CropFreeIcon/>]]
                    });
            }
            else {
                const error = new Error(res.error);
                throw error; }
            }).catch(err => {
                this.setState({...this.state, admin: false, sidebar: [['Tabellen', '', <TableChartIcon/>], ['Einstellungen', 'settings', <SettingsIcon/>], ['QR Code', 'qr', <CropFreeIcon/>]] })})
    }









    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Drawer
                        text={this.state.appBarText}
                        sideBar = {this.state.sidebar}
                />
            </React.Fragment>
        );
    }
}

export default App;