import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import DeleteDialog from "../Forms/Delete"

const useStyles = theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '80vh',
        justifyContent: 'space-around',
        flexDirection: 'column'

    },
    form: {
        marginTop: 20,
        width: 300,

    },
    buttons: {
        marginLeft: 20
    },


})

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSnackbar: false,
            openDeleteAlert: false,
            company: '',
            surename: '',
            lastname: '',
            email: '',
            password: ''
        };
    }

    handleClose = () => {
        this.setState({...this.state, openSnackbar: !this.state.openSnackbar})
    }

    handleChange = ({target: {value, id}}) =>
        this.setState((prevState) => {
            let stateUpdate = {...prevState}
            stateUpdate[id] = value
            return {...stateUpdate}
        })

    componentDidMount() {
        fetch('api/me').then(result => result.json()).then(data => this.setState({...this.state, ...data}))
    }

    handleSubmit = () => {
        fetch('api/me', {
            method: 'PUT', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
        }).then(this.handleClose)
    }

    handleDeleteToggle = () => {
        this.setState({...this.state, openDeleteAlert: !this.state.openDeleteAlert})
    }

    handleDelete = () => {
        fetch('api/me', {method: 'DELETE'}).then(this.setState({openDeleteAlert: false}))
    }


    render() {
        const {classes} = this.props;
        const {lastname, email, company, password, surename, openSnackbar, openDeleteAlert} = this.state
        return (
            <div className={classes.content}>
                <form className={classes.form}>
                    <TextField className={classes.form} id='company' label='Firma' value={company}
                               onChange={this.handleChange}/>
                    <br/>
                    <TextField className={classes.form} id='lastname' label='Name' value={lastname}
                               onChange={this.handleChange}/>
                    <br/>
                    <TextField className={classes.form} id='surename' label='Vorname' value={surename}
                               onChange={this.handleChange}/>
                    <br/>
                    <TextField className={classes.form} id='email' label='email' value={email}
                               onChange={this.handleChange}/>
                    <br/>
                    <TextField className={classes.form} id='password' label='Passwort' value={password}
                               onChange={this.handleChange} type="password"/>
                    <br/>
                </form>
                <div className={classes.form} style={{justifyContent: 'space-around'}}>
                    <Button type="submit" variant="contained" color="primary" onClick={this.handleSubmit}>
                        Ändern
                    </Button>
                    <Button className={classes.buttons} variant="contained" color="primary"
                            onClick={this.handleDeleteToggle}>
                        Löschen
                    </Button>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message="Änderungen gespeichert"
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>}
                />

                <DeleteDialog
                    deleteOpen={openDeleteAlert}
                    handleDelete={this.handleDelete}
                    handleDeleteToggle={this.handleDeleteToggle}
                    typ={'Durch bestätigen wird der Account unwiederruflich gelöscht!'}
                />

            </div>


        )
    }
}

export default withStyles(useStyles)(Settings)