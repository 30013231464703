import {columnsInventur as columns} from "../../../../../constants";
import TableCell from "@material-ui/core/TableCell";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";

const {memo} = require("react");

function areEqual(prevProps, nextProps) {
    if (prevProps.edit !== nextProps.edit) {
        return false
    }
    return true
}

export default memo(function (props) {
    const {rowElement, i, edit, handleChange, handleDelete} = props
    return (
        <TableRow
            key={i}
        >
            {columns.map((x) =>
                <TableCell
                    align={(x.key === 'lagerartikel' || x.key === 'typ') ? "left" : "right"}
                    style={{maxWidth:(x.key === 'lagerartikel') ? 400 : 120,  minWidth:(x.key === 'lagerartikel') ? 400 : 120}}
                >
                    {((x.key === 'menge' || x.key === 'ekpreis') && edit) ?
                        <NumberFormat
                            {...props}
                            id={rowElement.id + x.key}
                            label={x.name}
                            size={'small'}
                            isNumericString={true}
                            defaultValue={rowElement[x['key']]}
                            onValueChange={(value) => handleChange(value, x.key, rowElement.id)}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                        />
                        : (x.key === 'menge' || x.key === 'ekpreis' || x.key === 'wert' || x.key === 'pfandwert' || x.key === 'pfand') ?
                            Number(rowElement[x['key']] || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})
                            : (x.key === 'bereich')?
                                <div>
                                    <Tooltip title="Eintrag zukünftig nicht mehr zeigen">
                                        <IconButton size="small" onClick={() => handleDelete(rowElement.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip>

                                    {rowElement[x['key']]}
                                </div>
                                :
                            rowElement[x['key']]}
                </TableCell>)}
        </TableRow>)
}, areEqual)
