import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

export default function DeleteDialog(props) {
    const {handleDelete, handleDeleteToggle, typ, deleteOpen} = props

    return (
        <Dialog open={deleteOpen} onClose={handleDeleteToggle}>
            <DialogTitle>
                Löschen
            </DialogTitle>
            <DialogContent>
                {typ}
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleDeleteToggle}>
                    <CloseIcon/>
                </IconButton>
                <IconButton onClick={handleDelete}>
                    <CheckIcon/>
                </IconButton>
            </DialogActions>
        </Dialog>
    )
}
