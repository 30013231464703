import './App.css';
import jsPDF from 'jspdf';
import QRCode from "react-qr-code";
import {useState, useEffect} from "react";
import {renderToString} from "react-dom/server";
import { PDFDocument } from 'pdf-lib'
import React  from 'react';
import download  from 'downloadjs'

const svg_template = `
    <svg width="510px" height="260px">
    <rect x="0px" y="0px" width="510px" height="260px" fill="white"/>
  <!-- Placeholder for the QR code -->
        <g>
                paths_here
        </g>
  <!-- Text labels -->
  <text x="250px" y="68px" font-family="Arial" font-size="30" fill="black">mandant</text>
  <text x="250px" y="127px" font-family="Arial" font-size="30" fill="black">Interne</text>
  <text x="250px" y="169px" font-family="Arial" font-size="30" fill="black">Belegnummer:</text>
  <text x="250px" y="217px" font-family="Arial" font-size="30" fill="black">beleg</text>
</svg> `

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
const statsInit = [
    {name: "Mandant", id: "mandant", options: [], method: "dropdown", noNum: true},
    {name: "Belegart", id: "ka", options: ["KA", "CC"], method: "dropdown", noNum: true},
    {name: "Nr. Kasse/CC ", id: "kasse", method: "input", noNum: false},
    {name: "Jahr", id: "jahr", method: "input", noNum: false},
    {name: "Von", id: "von", method: "input", noNum: false},
    {name: "Bis", id: "bis", method: "input", noNum: false},

]
function Qr() {


    const initState = statsInit.reduce((o, key) => ((key.method == "input") ?  { ...o, [key.id]: ""} : { ...o, [key.id]: key.options[0]} ), {})

    const [state, setState] = useState(initState)
    const [stats, setStats] = useState(statsInit)



    const mergePdfs= async(pdfsToMerges) => {
        const mergedPdf = await PDFDocument.create();
        const actions = pdfsToMerges.map(async pdfBuffer => {
            const pdf = await PDFDocument.load(pdfBuffer);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
                // console.log('page', page.getWidth(), page.getHeight());
                // page.setWidth(210);
                mergedPdf.addPage(page);
            });
        });
        await Promise.all(actions);
        const mergedPdfFile = await mergedPdf.save();
        return mergedPdfFile;
    }

    const reset = () => {
        setState(initState)
    }

    useEffect(() =>  {
        fetch('api/restrictions').then(response => response.json()).then((data) => {
            const newData = [...new Set(data.map(x => x.mandanten.split(" ")[0]))]
            let newState = [...stats]
            newState[0].options = [...newData]
            setStats(newState)
            setState({...state, 'mandant': newData[0]})
        })
    }, [])


  const createQR=async () => {
      for (let o of Object.keys(state)) {
          const noNum = stats.filter((x) => x.id == o)[0].noNum
          if (noNum) {
              continue
          }
          if (isNaN(state[o]) || state[o].length===0)
          {
              console.log(state)
              alert("Alle Werte müssen Nummern sein");
              return;
          }
      }
      if (state.von > state.bis) {
          alert("Bis: muss größer gleich Von: sein!")
          return
      }
      let my_docs = []
      for (let l=state.von; l<=state.bis; l++) {
          const doc = new jsPDF({format: [260, 510], orientation: "l"});
          const myQRCode = renderToString(<QRCode
              value={`${state.mandant.substring(1)}${state.ka}${pad(state.kasse, 2)}${state.jahr[state.jahr.length-1]}${pad(l, 6)}`}
              height={200}
              width={200}
              x={30}
              y={30}
          />)
          let c_svg = svg_template
          c_svg = c_svg.replace("paths_here", myQRCode)
          c_svg = c_svg.replaceAll(256, 200)
          c_svg = c_svg.replace("mandant", "Mandant: " + state.mandant.substring(1))
          c_svg = c_svg.replace("beleg", `${state.ka}${pad(state.kasse, 2)}${state.jahr[state.jahr.length-1]}${pad(l, 6)}`)
         
          await doc.addSvgAsImage(c_svg, 0, 0, 510, 260, '', true)
          my_docs.push(doc.output('arraybuffer'))
      }
      let doc = await mergePdfs(my_docs)
      download(doc, `${state.mandant }${state.ka}${pad(state.kasse, 2)}${state.jahr[state.jahr.length-1]}${state.von}-${state.bis}.pdf`, "application/pdf")
      reset()
    }

  return (
    <div className="App">
      <header className="App-header">
        <h1>QR Code Generator</h1>
        <div style={{
                  flexDirection:'row',
                  display:'flex',
                  justifyContent:'space-between',
		  flexWrap:'wrap',
                  width: '70%'
                }}
             onKeyDown={(e) => {if (e.key==='Enter') createQR()}}>
            {stats.map((x) => (
                <div style={{width: "15%", minWidth: '150px', marginRight: "10px"}} >
                    <label>{x.name}</label> <br/>
                    {(x.method == "input") ?
                        <input type="text" id={x.id} value={state[x.id]}
                               onChange={(e) => setState({...state, [x.id]: e.target.value})}/>
                        :
                        <select id={x.id} value={state[x.id]}
                                onChange={(e) => setState({...state, [x.id]: e.target.value})}
                        >
                            {x.options.map((y) => (
                                <option value={y}>{y}</option>
                            ))}
                        </select>
                    }
                </div>
                )
            )
            }
        </div>
        <button style={{marginTop: '20px'}} onClick={createQR}>
            <h3>
              Erstellen
            </h3>
        </button>
      </header>
    </div>
  );
}

export default Qr;
