import React, {Component, Fragment} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Overview from '../Wohngeld/Overview'
import { wirtschaftsplan, wirtschaftsplan_gesamt } from "./constants"
import AddButton from "../AddButton";
import DeleteDialog from "../../Forms/Delete";



const useStyles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
        position: 'absolute',
        bottom: 0
    }

})


function createEmptyObject(columns) {
    const obj = {}
    for (const key of columns) {
        obj[key.field] = null
    }

    return obj
}


class Wirtschaftsplan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSheet: 0,
            id: 0,
            toDelete: 0,
            edit: false,
            data: [],
            data_gesamt: {},
            underEdit: {0: createEmptyObject(wirtschaftsplan)},
            editGesamt: false,
            rowData: createEmptyObject(wirtschaftsplan),
            editHeader: false,
            headerData: createEmptyObject(wirtschaftsplan),
        }
    }


    componentDidMount() {
        fetch('/api/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.props.tableId,
                type: 'wirtschaftsplan'
            })}).then(res => res.json()).then((data) => this.setState({...this.state, data: data.rows, rowData: data.gesamt[0]||createEmptyObject(wirtschaftsplan_gesamt), headerData: data.header[0]||createEmptyObject(wirtschaftsplan)}))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.componentDidMount()
        }
    }

    toggleEdit = (id, row) => {
        this.setState({...this.state, underEdit: {...this.state.underEdit, [id]: {...row, selection_id: this.props.tableId}}, id: id, edit: true})
    }


    handleDataDeleteCheck = (id) => {
        this.setState({...this.state, toDelete: id})
    }

    handleDataDelete = () => {
        const toDelete = this.state.toDelete
        fetch('/api/deleteKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id:toDelete,
            })}).then(() =>
            this.setState({...this.state, data: [...this.state.data.filter((item) => item.id !== toDelete)],  toDelete: 0}))
    }

    deleteCancel = () => {
        this.setState({...this.state, toDelete: 0})
    }

    toggleEditGesamtHeader = (field) => {
        this.setState({...this.state, [field]: true})
    }



    submitChange = (id) => {
        fetch('/api/addChangeKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                selection_id: this.props.tableId,
                data: {...this.state.underEdit[id]},
                data_gesamt: {...this.state.rowData},
                header_data: {...this.state.headerData}
            })
        }).then((res) => res.json()).then((response) =>
            this.setState((prevState) => {
                const data = prevState.data
                if (id === 0) {
                    data.push({...prevState.underEdit[id], id: response.id})
                } else if (id !== 2 && id !== 1){
                    const index = data.findIndex((x) => x.id === response.id)
                    data[index] = {...prevState.underEdit[id]}
                }
                return {...prevState, data: data}
            }, () =>
                this.clearChanges(id))
        )
    }


    clearChanges = (id) => {
        this.setState((prevState) => {
            const edit = prevState.underEdit
            if (id === 0) {
                edit[0] = createEmptyObject(wirtschaftsplan)
            } else {
                delete edit[id]
            }
            return {...prevState, underEdit: edit, id: 0, edit: false, editGesamt: false, editHeader: false}
        })
    }

    handleChanges = (newValue, id, field) => {
        if (id === 1) {
            this.setState({
                ...this.state,
                headerData: {...this.state.headerData, [field]: newValue}
            })
        }
        else if (id === 2) {
            this.setState({
                ...this.state,
                rowData: {...this.state.rowData, [field]: newValue}
            })
        }
        else {
            this.setState({
                ...this.state,
                underEdit: {...this.state.underEdit, [id]: {...this.state.underEdit[id], [field]: newValue}}
            })
        }
    }


    render() {
        const { data, underEdit, id, toDelete, rowData, edit, editGesamt, editHeader, headerData } = this.state
        const {mandanten, jahr, write} = this.props
        return (
            <Fragment>
                <Paper style={{margin: 'auto', height: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}}>
                    <Overview
                        mandanten={mandanten}
                        jahr = {jahr}
                        columns={wirtschaftsplan}
                        columnsGesamt={wirtschaftsplan_gesamt}
                        editHeader={editHeader}
                        headerData={headerData}
                        editableHeader={true}
                        data={data}
                        underEdit={underEdit}
                        id={id}
                        editGesamt={editGesamt}
                        rowData={rowData}
                        toggleEdit={this.toggleEdit}
                        handleDataDelete={this.handleDataDeleteCheck}
                        toggleEditGesamt={this.toggleEditGesamtHeader}
                        submitChange={this.submitChange}
                        clearChanges={this.clearChanges}
                        handleChanges={this.handleChanges}
                        edit={edit}
                    />
                    <AddButton write={write}
                               addOpen={() => this.toggleEdit(0, {})}
                    />
                    <DeleteDialog
                        handleDelete={this.handleDataDelete}
                        handleDeleteToggle={this.deleteCancel}
                        typ={'Wollen Sie diesen Eintrag wirklich unwiederuflich löschen?'}
                        deleteOpen={toDelete}
                    />

                </Paper>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(Wirtschaftsplan)