import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";


export default function SumGetter(props) {
    const {columns, data, word} = props
        return (
            <TableRow>
                <TableCell>
                    {word}
                </TableCell>
                {
                    columns.map((entry) =>
                        <TableCell
                            align={entry.align || "center"}
                        >
                            {(entry.sumGetter)? entry.valueFormatter(entry.sumGetter(data, entry.field)) : ''}
                        </TableCell>
                    )
                }
            </TableRow>
        )

}