export const wirtschaftsplan = [
    {
        field:   'mdt',
        headerName: (year) => 'Mdt.',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   'wie',
        headerName: (year) => 'WIE',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   'obj',
        headerName: (year) => 'Obj.',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   've',
        headerName: (year) => 'VE',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   've_bezeichnung',
        headerName: (year) => 'VE-Bezeichnung',
        width: 200,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'etage',
        headerName: (year) => 'Etage',
        width: 100,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'lage',
        headerName: (year) => 'Lage',
        width: 200,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'mibu',
        headerName: (year) => 'm² MIBU',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zugang',
        headerName: (year) => 'Zugang.',
        width: 100,
        type: 'date',
        valueFormatter: (params) => params
    },
    {
        field:   'abgang',
        headerName: (year) => 'Abgang.',
        width: 100,
        type: 'date',
        valueFormatter: (params) => params
    },
    {
        field:   'vertrag',
        headerName: (year) => 'Vertrag.',
        width: 100,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'wf_',
        headerName: (year) => 'WF',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'hf_',
        headerName: (year) => 'HF',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v1_',
        headerName: (year) => 'V1',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v2_',
        headerName: (year) => 'V2',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v3_',
        headerName: (year) => 'V3',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'we_',
        headerName: (year) => 'WE',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'te_',
        headerName: (year) => 'TE',
        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'wp_diff_',
        headerName: (year) => 'WP-Diff.',
        secondHeaderName: (params) => params,

        width: 100,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'gesamt_ve',
        headerName: (year) => 'Gesamt VE',
        secondHeaderName: (params) => params,

        width: 140,
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumber(params)
    },
]

function valueNumberFourDigit(params) {
    return Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 4, maximumFractionDigits: 4})
}

export const wirtschaftsplan_gesamt = [
    {
        field:   'wf_gesamt',
        headerName: (year) => 'WF',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'hf_gesamt',
        headerName: (year) => 'HF',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'v1_gesamt',
        headerName: (year) => 'V1',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'v2_gesamt',
        headerName: (year) => 'V2',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'v3_gesamt',
        headerName: (year) => 'V3',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'we_gesamt',
        headerName: (year) => 'WE',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'te_gesamt',
        headerName: (year) => 'TE',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'wp_diff_gesamt',
        headerName: (year) => 'WP-Diff.',
        width: 100,
        type: 'number',
        notEditable: true,
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'gesamt_vegesamt',
        headerName: (year) => 'Gesamt VE',
        width: 140,
        type: 'number',
        notEditable: true,
        valueFormatter: (params) => valueNumber(params)
    },
]



function valueNumber(params) {
    return Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})
}
