import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import React, {Fragment} from "react";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";



const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));



export default function EditDialog(props) {
    const classes = useStyles()
    const {params, edit, row, id, handleSubmit, handleClear, handleChange, year} = props
    return (
        <Dialog open={edit} maxWidth={'lg'}>
            <DialogTitle>
                Neue Daten eingeben.
            </DialogTitle>
            <DialogContent className={classes.root}>
                {params.map((entry) =>
                    (entry.secondHeaderName) &&
                        <TextField
                            value={row[entry.field]}
                            label={entry.headerName(year)}
                            onChange={(event) => handleChange(event.target.value, id, entry.field)}
                        />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleClear(id)}>
                    Abbrechen
                </Button>
                <Button color="primary" onClick={() => handleSubmit(id)}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
}