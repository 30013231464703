import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import  logo_adlatus from "../../bilder/logo_adlatus.png"
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";


export default class Pwreset extends Component {
    constructor(props) {
        super(props);
        this.state = {
           username: "",
            code: "",
            neuesPw: "",
            backdropOpen:false

        }
    }
    handleChange =  (newValue, key) => {
        this.setState({...this.state, [key]: newValue})
    }

    handleSubmit = () => {
        this.setState({...this.state, backdropOpen:true}, () => {
        fetch('api/pwChange', {method: 'POST', headers: {
            'Content-Type': 'application/json'
        }, body: JSON.stringify({username:this.state.username, code:this.state.code, password: this.state.neuesPw})})
            .then((res)=>
                {
                    if (res.status === 200)
                        {
                            this.props.history.push('/');
                        }
                    else {
                            this.setState({...this.state, backdropOpen:false}, () => {
                            res.json().then((data) => {

                            if (res.status === 401) {
                                alert(data.error)
                            } else if (res.status === 500) {
                                alert(data.error)
                            } else {
                                const error = new Error(data.error);
                                throw error;
                            }
                           })})}
                })
            })
        }


    render() {
        const {username, code, neuesPw, backdropOpen} = this.state

        return (
           <Container maxWidth="md" style={{height:"100vh"}}>
               <Grid container style={{height:"100vh", alignItems:"center"}}>
                <Grid container spacing={3}>
                    <Grid container item  >
                        <img
                            style={{marginLeft: "auto", marginRight: "auto"}}
                            src={logo_adlatus}
                            alt={""}
                        />
                    </Grid>
                   <Grid container item  >
                       <TextField
                           style={{marginLeft: "auto", marginRight: "auto"}}
                           label="Username"
                           value={username}
                           onChange={(event) => this.handleChange(event.target.value, "username")}
                       />
                   </Grid>
                   <Grid container item>
                       <TextField
                           style={{marginLeft: "auto", marginRight: "auto"}}
                           label="Code"
                           value={code}
                           onChange={(event) => this.handleChange(event.target.value, "code")}
                       />
                   </Grid>
                   <Grid container item>
                       <TextField
                           style={{marginLeft: "auto", marginRight: "auto"}}
                           type="password"
                           label="Neues Passwort"
                           value={neuesPw}
                           onChange={(event) => this.handleChange(event.target.value, "neuesPw")}
                       />
                   </Grid>
                    <Grid container item>
                        <Button variant="contained" color="primary" style={{marginLeft: "auto", marginRight: "auto"}} onClick={this.handleSubmit}>
                            Login
                        </Button>
                    </Grid>
                </Grid>
               </Grid>
               <Backdrop open={backdropOpen} style={{zIndex: 99}}>
                   <CircularProgress color="inherit" />
               </Backdrop>
           </Container>


        );
    }
}
