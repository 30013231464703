import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {typeItems} from "../../../../constants";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {

        marginTop: theme.spacing(2),
    },
    select: {
        position: "sticky",

    },
    spacer: {
        display: 'flex',
        justifyContent: "space-between",

    },
    buttonGroup: {
        marginRight: 10
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));

export default function Selection(props) {
    const {
        type,
        changeType
    } = props
    const classes = useStyles()

    return (
        <div className={classes.spacer} id={'Selection'}>
            <div className={classes.select}>
                <FormControl className={classes.formControl}>
                    <InputLabel id={'Tabelle'}>Tabelle</InputLabel>
                    <Select value={type}
                            onChange={(event) => changeType(event.target.value)}>
                        {typeItems.map(x => (<MenuItem value={x}>
                            {x.name}
                        </MenuItem>))}
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>

        </div>
    )
}