import React, {Fragment} from 'react'
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Row from "./AbrechnungRow"
import Overview from "./Overview"
import {
    abrechnung_wohngeld as columns,
    wohngeld_overview_gesamt as gesamt,
} from "./constants";
import AddButton from "../AddButton";
import DeleteDialog from "../../Forms/Delete";
import Paper from "@material-ui/core/Paper";


const useStyles = theme => ({
    borders: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
    },
    bordersBottom: {
        borderBottom: '1px solid black'
    },
    bordersNotRight: {
        borderLeft: '1px solid black',
        borderTop: '1px solid black'
    },
    center: {
        margin: 'auto',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
    }

})

function createEmptyObject(columns) {
    const obj = {}
    for (const key of columns) {
        obj[key.field] = ''
    }

    return obj
}




class Abrechnung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            edit: false,
            rowData: createEmptyObject(columns),
            id:0,
            underEdit: {0: createEmptyObject(columns)},
            autoComplete: [createEmptyObject(columns)],
            autoCompleteValue: createEmptyObject(columns),
            toDelete: 0
        }
    }

    componentDidMount() {
        fetch('/api/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.props.tableId,
                type: 'wohngeld',
                abrechnung: true
            })}).then(res => res.json()).then((data) => this.setState({...this.state, data: data.rows || []}))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.componentDidMount()
        }
    }

    toggleEdit = (id, row) => {
        this.setState({...this.state, autoComplete: [{...row}], autoCompleteValue: {sachkonten: {...row}}}, () => {
        this.setState({...this.state, underEdit: {...this.state.underEdit, [id]: {...row}}, id: id, edit: true})})
    }

    handleDataDeleteCheck = (id) => {
        this.setState({...this.state, toDelete: id})
    }

    deleteCancel = () => {
        this.setState({...this.state, toDelete: 0})
    }

    handleDataDelete = () => {
        const toDelete = this.state.toDelete
        fetch('/api/deleteKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id:toDelete,
            })}).then(() =>
            this.setState({...this.state, data: [...this.state.data.filter((item) => item.id !== toDelete)],  toDelete: 0}))
    }


    submitChange = (id) => {
        fetch('/api/addChangeKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                selection_id: this.props.tableId,
                data: {...this.state.underEdit[id], abrechnung: '1'}
            })
        }).then((res) => res.json()).then((response) =>
            this.setState((prevState) => {
                const data = prevState.data
                if (id === 0) {
                    data.push({...prevState.underEdit[id], id: response.id})
                } else if (id !== 2){
                    const index = data.findIndex((x) => x.id === response.id)
                    data[index] = {...prevState.underEdit[id]}
                }
                return {...prevState, data: data}
            }, () =>
                this.clearChanges(id))
        )
    }



    clearChanges = (id) => {
        if (id === 2) {
            this.toggleEditGesamt()
        }
        else {
            this.setState((prevState) => {
                const edit = prevState.underEdit
                if (id === 0) {
                    edit[0] = createEmptyObject(columns, this.props.tableId)
                } else {
                    delete edit[id]
                }
                return {...prevState, underEdit: edit, id: 0, edit: false}
            })
        }

    }

    handleValueChange = (newValue, id, field) => {
        this.setState({...this.state, autoCompleteValue: {...this.state.autoCompleteValue, [field]: newValue}})
    }

    handleChanges = (newValue, id, field, autocomplete) => {
        console.log(newValue)
        if (autocomplete && newValue.length > 1) {
            fetch('/api/getOptionsWohngeld', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    kontonummer: newValue,
                })}).then(res => res.json()).then((data) => this.setState((prevState) => {
                    let underEdit = prevState.underEdit[id]
                    if (data.length === 1){
                        underEdit = {...underEdit, ...data[0]}
                    }
                    return {...prevState, autoComplete: [...data], underEdit: {...prevState.underEdit, [id]: underEdit}}
                }
            ))
        }
        else if (autocomplete) {
            this.setState({...this.state, autoComplete: [createEmptyObject(columns)]})
        }

        if (id === 2) {
            this.setState({...this.state, rowData: {...this.state.rowData, [field]: newValue}})
        }
        else {
            this.setState({
                ...this.state,
                underEdit: {...this.state.underEdit, [id]: {...this.state.underEdit[id], [field]: newValue}}
            })
        }

    }






    render() {
        const {data, underEdit, id, rowData, edit, autoComplete, toDelete,autoCompleteValue} = this.state
        const {classes, dataOverview, dataPart, mandanten, jahr, write } = this.props;

        return(
            <Fragment>
                <Paper>
                    <Grid container style={{ width: 'calc(100vw - ' + document.getElementById("Sidebar").clientWidth + 'px)'}} spacing={0} id={"Grid"}>
                        <Grid container style={{justifyContent: 'center'}}>
                            <Grid item xs={6} className={classes.borders}>
                                Aufteilungsschlüssel
                            </Grid>
                        </Grid>
                        <Row
                            flaeche1={'Wohnfläche'}
                            kurz1={'WF'}
                            flaeche1GesamtName={'qm gesamt'}
                            flaeche1TeilName={'qm Derag'}
                            flaeche1Gesamt={gesamt[0].valueFormatter(dataOverview['wfgesamt'])}
                            flaeche1Teil={gesamt[0].valueFormatter(dataPart['wfgesamt'])}
                            flaeche2={'Verteilung 2'}
                            kurz2={'V2'}
                            flaeche2GesamtName={'gesamt'}
                            flaeche2TeilName={'Derag'}
                            flaeche2Gesamt={gesamt[0].valueFormatter(dataOverview['v2gesamt'])}
                            flaeche2Teil={gesamt[0].valueFormatter(dataPart['v2gesamt'])}
                            borderBottom={false}
                        />
                        <Row
                            flaeche1={'Heizfläche'}
                            kurz1={'HF'}
                            flaeche1GesamtName={'qm gesamt'}
                            flaeche1TeilName={'qm Derag'}
                            flaeche1Gesamt={gesamt[0].valueFormatter(dataOverview['hfgesamt'])}
                            flaeche1Teil={gesamt[0].valueFormatter(dataPart['hfgesamt'])}
                            flaeche2={'Verteilung 3'}
                            kurz2={'V3'}
                            flaeche2GesamtName={'gesamt'}
                            flaeche2TeilName={'Derag'}
                            flaeche2Gesamt={gesamt[0].valueFormatter(dataOverview['v3gesamt'])}
                            flaeche2Teil={gesamt[0].valueFormatter(dataPart['v3gesamt'])}
                            borderBottom={false}
                        />
                        <Row
                            flaeche1={'Verteilung 1'}
                            kurz1={'V1'}
                            flaeche1GesamtName={'1000tel gesamt'}
                            flaeche1TeilName={'1000tel Derag'}
                            flaeche1Gesamt={gesamt[0].valueFormatter(dataOverview['v1gesamt'])}
                            flaeche1Teil={gesamt[0].valueFormatter(dataPart['v1gesamt'])}
                            flaeche2={'Anzahl WHG'}
                            kurz2={'WE'}
                            flaeche2GesamtName={'Anzahl gesamt'}
                            flaeche2TeilName={'Anzahl Derag'}
                            flaeche2Gesamt={gesamt[0].valueFormatter(dataOverview['wegesamt'])}
                            flaeche2Teil={gesamt[0].valueFormatter(dataPart['wegesamt'])}
                            borderBottom={false}
                        />
                        <Row
                            flaeche1={'Fest'}
                            kurz1={'F'}
                            flaeche1GesamtName={' '}
                            flaeche1TeilName={' '}
                            flaeche1Gesamt={0}
                            flaeche1Teil={0}
                            flaeche2={'Anzahl TG'}
                            kurz2={'TE'}
                            flaeche2GesamtName={'Anzahl gesamt'}
                            flaeche2TeilName={'Anzahl Derag'}
                            flaeche2Gesamt={gesamt[0].valueFormatter(dataOverview['tegesamt'])}
                            flaeche2Teil={gesamt[0].valueFormatter(dataPart['tegesamt'])}
                            borderBottom={true}
                        />
                    </Grid>
                    <Paper
                        elevation={0}
                        style={{display: "flex",
                        justifyContent: "center",
                        height: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px - ' + (document.getElementById("Tabs")||{clientHeight:0}).clientHeight + 'px - ' + (document.getElementById("Grid")||{clientHeight:0}).clientHeight + 'px)'}}>

                    <Overview
                            mandanten={mandanten}
                            jahr = {jahr}
                            columns={columns}
                            columnsGesamt={[]}
                            data={data}
                            underEdit={underEdit}
                            id={id}
                            editGesamt={false}
                            rowData={rowData}
                            toggleEdit={this.toggleEdit}
                            handleDataDelete={this.handleDataDeleteCheck}
                            toggleEditGesamt={() => ''}
                            submitChange={this.submitChange}
                            clearChanges={this.clearChanges}
                            handleChanges={this.handleChanges}
                            edit={edit}
                            dataGesamt={dataOverview}
                            dataPart={dataPart}
                            autoComplete={autoComplete}
                            autoCompleteValue={autoCompleteValue}
                            handleValueChange={this.handleValueChange}
                        />
                    </Paper>
                    <AddButton write={write}
                               addOpen={() => this.toggleEdit(0, createEmptyObject(columns))}
                    />
                    <DeleteDialog
                        handleDelete={this.handleDataDelete}
                        handleDeleteToggle={this.deleteCancel}
                        typ={'Wollen Sie diesen Eintrag wirklich unwiederuflich löschen?'}
                        deleteOpen={toDelete}
                    />
                </Paper>
            </Fragment>
        )
    }



}

export default withStyles(useStyles)(Abrechnung)
