import React from "react";

export default function (props) {
    const {left, value, width, scroll} = props
    return (
        <div className="react-grid-Cell  react-grid-Cell--frozen rdg-last--frozen" style={{
            position: 'absolute',
            width: width + scroll,
            height: 35,
            marginLeft: left,
        }}>
            <div className="react-grid-Cell__value">
                <div style={{
                    marginLeft: 0,
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)'
                }}>
                <span>
                    <div style={{textAlign: 'right', fontWeight: "bold"}}>
                        {value}
                    </div>
                </span>
                </div>
            </div>
        </div>
    )

}
