import React from "react";
import ReactDataGrid from "react-data-grid";

export default function Table(props) {
    const {
        data,
        columns,
        getCellActions,
        onGridRowsUpdated
    } = props

    return (
        <ReactDataGrid
            minHeight={'calc(100vh - ' + document.getElementById("AppBar").clientHeight + 'px  - ' + (document.getElementById("Selection")||{clientHeight: 0}).clientHeight + 'px)'}
            columns={columns}
            rowsCount={data.length}
            rowGetter={i => data[i]}
            getCellActions={getCellActions}
            onGridRowsUpdated={onGridRowsUpdated}
            enableCellSelect={true}
            enableDragAndDrop={false}
        />
    )
}