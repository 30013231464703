import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import SingleRow from "./SingleRow";
import {columnsInventur as columns} from "../../../../../constants";


const {memo} = require("react");


function areEqual(prevProps, nextProps) {
    if (prevProps.open !== nextProps.open) {
        return false
    }
    return true
}

export default memo(function (props) {
    const {cat, catResult, handleChange, open, openBox, row, handleEditSave, edit, toggleEdit, handleDelete} = props;

    function handleEdit(cat) {
        if (edit) {
            handleEditSave(cat)
            toggleEdit(cat, false)
        } else {
            toggleEdit(cat, true)
        }
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{maxWidth:120, minWidth:120}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => openBox(cat)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell style={{maxWidth:120, minWidth:120}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleEdit(cat)}>
                        {edit ? < DoneIcon/> : <EditIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{maxWidth:400, minWidth:400}}>
                    {cat}
                </TableCell>

                <TableCell colSpan={4} style={{maxWidth:480, minWidth:480}}/>
                <TableCell align="right" width={120}>
                    {Number(catResult[0] || '').toLocaleString("de-DE", {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell style={{maxWidth:120, minWidth:120}}/>
                <TableCell align="right" style={{maxWidth:120, minWidth:120}}>
                    {Number(catResult[1] || '').toLocaleString("de-DE", {minimumFractionDigits: 2})}
                </TableCell>
            </TableRow>
            <TableRow>
                {columns.map((x) =>
                    <TableCell
                        style={{maxWidth:(x.key === 'lagerartikel') ? 400 : 120,  minWidth:(x.key === 'lagerartikel') ? 400 : 120}}
                        maxHeight={0}
                    />)}
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0, margin: 0}} colSpan={10}>
                    <Collapse style={{padding: 0, margin: 0}} in={open} timeout="auto" unmountOnExit>
                        {row.map((rowElement, i) => (
                            <SingleRow
                                rowElement={rowElement}
                                i={i}
                                handleDelete={handleDelete}
                                handleChange={handleChange}
                                edit={edit}
                                cat={cat}
                            />
                        ))}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}, areEqual)

