import React, {Component, Fragment} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Overview from './Overview'
import Tabs from "@material-ui/core/Tabs";
import {wohngeld_overview as overview, wohngeld_kontrolle as kontrolle, wohngeld_overview_gesamt as overview_gesamt} from "./constants"
import AddButton from "../AddButton";
import Abrechnung from "./Abrechnung";
import DeleteDialog from "../../Forms/Delete";


const useStyles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    tabs: {
        position: 'absolute',
        bottom: 0
    }

})


function createEmptyObject(columns) {
    const obj = {}
    for (const key of columns) {
        obj[key.field] = null
    }

    return obj
}

function getPartofColumn(data) {
    const partData = {}
    overview.forEach((key) => {
        if (key.secondSumGetter){
            partData[key.field + 'gesamt'] = key.sumGetter(data, key.field)
        }
    })
    return partData
}

class Wohngeld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSheet: 0,
            id: 0,
            edit: false,
            data: [],
            underEdit: {0: createEmptyObject(overview)},
            editGesamt: false,
            rowData: createEmptyObject(overview_gesamt),
            toDelete: 0
        }
    }

    handleTabChange = (event, value) => {
        this.setState({...this.state, selectedSheet: value})
    }



    componentDidMount() {
        fetch('/api/data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.props.tableId,
                type: 'wohngeld',
                abrechnung: false
            })}).then(res => res.json()).then((data) => this.setState({...this.state, data: data.rows, rowData: data.gesamt[0]||createEmptyObject(overview_gesamt)}))
    }

    toggleEdit = (id, row) => {
        this.setState({...this.state, underEdit: {...this.state.underEdit, [id]: {...row, selection_id: this.props.tableId}}, id: id, edit: true})
    }

    toggleEditGesamt = () => {
        this.setState({...this.state, editGesamt: !this.state.editGesamt})
    }

    handleDataDeleteCheck = (id) => {
        this.setState({...this.state, toDelete: id})
    }

    deleteCancel = () => {
        this.setState({...this.state, toDelete: 0})
    }

    handleDataDelete = () => {
        const toDelete = this.state.toDelete
        fetch('/api/deleteKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id:toDelete,
            })}).then(() =>
            this.setState({...this.state, data: [...this.state.data.filter((item) => item.id !== toDelete)],  toDelete: 0}))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId || prevProps.update !== this.props.update) {
            this.componentDidMount()
        }
    }


    submitChange = (id) => {
        fetch('/api/addChangeKreditor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                selection_id: this.props.tableId,
                data: {...this.state.underEdit[id]},
                data_gesamt: {...this.state.rowData}
            })
        }).then((res) => res.json()).then((response) =>
            this.setState((prevState) => {
                const data = prevState.data
                if (id === 0) {
                    data.push({...prevState.underEdit[id], id: response.id})
                } else if (id !== 2){
                    const index = data.findIndex((x) => x.id === response.id)
                    data[index] = {...prevState.underEdit[id]}
                }
                return {...prevState, data: data}
            }, () =>
                this.clearChanges(id))
        )
    }

    clearChanges = (id) => {
        if (id === 2) {
            this.toggleEditGesamt()
        }
        else {
            this.setState((prevState) => {
                const edit = prevState.underEdit
                if (id === 0) {
                    edit[0] = createEmptyObject(overview, this.props.tableId)
                } else {
                    delete edit[id]
                }
                return {...prevState, underEdit: edit, id: 0, edit: false}
            })
        }

    }

    handleChanges = (newValue, id, field) => {
        if (id === 2) {
            this.setState({...this.state, rowData: {...this.state.rowData, [field]: newValue}})
        }
        else {
            this.setState({
                ...this.state,
                underEdit: {...this.state.underEdit, [id]: {...this.state.underEdit[id], [field]: newValue}}
            })
        }
    }


    render() {
        const { selectedSheet, data, underEdit, id, editGesamt, rowData, edit, toDelete } = this.state
        const {mandanten, jahr, write, classes, tab, tableId} = this.props
        return (
            <Fragment>
                <Paper style={{display: "flex",
                    justifyContent: "center",
                     height: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}}>
                        <Paper style={{display: "flex",
                            justifyContent: "center",
                            height: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px - ' + (document.getElementById("Tabs")||{clientHeight:0}).clientHeight + 'px)'}}>

                            {(selectedSheet === 0) && <Overview
                                mandanten={mandanten}
                                jahr = {jahr}
                                columns={overview}
                                columnsGesamt={overview_gesamt}
                                data={data}
                                underEdit={underEdit}
                                id={id}
                                editGesamt={editGesamt}
                                rowData={rowData}
                                toggleEdit={this.toggleEdit}
                                handleDataDelete={this.handleDataDeleteCheck}
                                toggleEditGesamt={this.toggleEditGesamt}
                                submitChange={this.submitChange}
                                clearChanges={this.clearChanges}
                                handleChanges={this.handleChanges}
                                edit={edit}
                            />}
                            {(selectedSheet === 1) && <Overview
                                mandanten={mandanten}
                                jahr = {jahr}
                                columns={kontrolle}
                                columnsGesamt={[]}
                                data={data}
                                underEdit={underEdit}
                                id={id}
                                editGesamt={editGesamt}
                                rowData={rowData}
                                toggleEdit={this.toggleEdit}
                                handleDataDelete={this.handleDataDeleteCheck}
                                toggleEditGesamt={this.toggleEditGesamt}
                                submitChange={this.submitChange}
                                clearChanges={this.clearChanges}
                                handleChanges={this.handleChanges}
                                edit={edit}
                            />}
                            {(selectedSheet === 2) && <Abrechnung
                                dataOverview={rowData}
                                dataPart={getPartofColumn(data)}
                                mandanten={mandanten}
                                jahr={jahr}
                                write={write}
                                tab={tab}
                                tableId={tableId}

                            />}
                        </Paper>
                    <Tabs
                        id={'Tabs'}
                        value={selectedSheet}
                        className={classes.tabs}
                        onChange={(event, value) => this.handleTabChange(event, value)}
                        indicatorColor="secondary"
                        textColor="primary"
                    >
                        {[{key: 1, text: 'Übersicht Abrechnung'}, {key: 2, text: 'Kontrolle Abrechnung'}, {
                            key: 3,
                            text: 'WG Abrechg'
                        }].map((x) => <Tab label={x.text}/>)}
                    </Tabs>


                    {(selectedSheet === 1 || selectedSheet === 0) && <AddButton write={write}
                                                                                addOpen={() => this.toggleEdit(0, {})}
                    />}



                    <DeleteDialog
                        handleDelete={this.handleDataDelete}
                        handleDeleteToggle={this.deleteCancel}
                        typ={'Wollen Sie diesen Eintrag wirklich unwiederuflich löschen?'}
                        deleteOpen={toDelete}
                    />
                </Paper>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(Wohngeld)