
export const wohngeld_overview = [
    {
        field:   'mdt',
        headerName: (year) => 'Mdt.',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   'wie',
        headerName: (year) => 'WIE',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   'obj',
        headerName: (year) => 'Obj.',
        width: 50,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   've',
        headerName: (year) => 'VE',
        width: 100,
        type: 'integer',
        valueFormatter: (params) => params
    },
    {
        field:   've_bezeichnung',
        headerName: (year) => 'VE-Bezeichnung',
        align: 'left',
        width: 300,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'etage',
        headerName: (year) => 'Etage',
        width: 200,
        align: 'left',
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'lage',
        headerName: (year) => 'Lage',
        width: 100,
        align: 'left',
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'mibu',
        headerName: (year) => 'm² MIBU',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zugang',
        headerName: (year) => 'Zugang.',
        width: 100,
        type: 'date',
        valueFormatter: (params) => params
    },
    {
        field:   'abgang',
        headerName: (year) => 'Abgang.',
        width: 100,
        type: 'date',
        valueFormatter: (params) => params
    },
    {
        field:   'vertrag',
        headerName: (year) => 'Vertrag.',
        width: 100,
        type: 'text',
        valueFormatter: (params) => params
    },
    {
        field:   'wf',
        headerName: (year) => 'WF',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'hf',
        headerName: (year) => 'HF',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v1',
        headerName: (year) => 'V1',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v2',
        headerName: (year) => 'V2',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v3',
        headerName: (year) => 'V3',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'we',
        headerName: (year) => 'WE',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'te',
        headerName: (year) => 'TE',
        width: 100,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'gesamt_ve',
        headerName: (year) => 'Gesamt VE',
        width: 140,
        align: 'right',
        type: 'number',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        secondSumGetter: (sumGetter, gesamt) => Number(gesamt||0) - sumGetter,
        valueFormatter: (params) => valueNumber(params)
    },
]

export const wohngeld_overview_gesamt = [
    {
        field:   'wfgesamt',
        headerName: (year) => 'WF',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'hfgesamt',
        headerName: (year) => 'HF',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v1gesamt',
        headerName: (year) => 'V1',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v2gesamt',
        headerName: (year) => 'V2',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'v3gesamt',
        headerName: (year) => 'V3',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'wegesamt',
        headerName: (year) => 'WE',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'tegesamt',
        headerName: (year) => 'TE',
        width: 100,
        type: 'number',
        valueFormatter: (params) => valueNumberFourDigit(params)
    },
    {
        field:   'gesamt_vegesamt',
        headerName: (year) => 'Gesamt VE',
        width: 140,
        type: 'number',
        notEditable: true,
        valueFormatter: (params) => valueNumber(params)
    },]

export const wohngeld_kontrolle = [
    {
        field:   'wie',
        width: 100,
        headerName: (year) => 'WIE',
        valueFormatter: (params) => params
    },
    {
        field:   'obj',
        width: 50,
        headerName: (year) => 'Obj.',
        valueFormatter: (params) => params
    },
    {
        field:   've',
        width: 100,
        headerName: (year) => 'VE',
        valueFormatter: (params) => params
    },
    {
        field:   've_bezeichnung',
        align: 'left',
        width: 300,
        headerName: (year) => 'VE-Bezeichnung',
        valueFormatter: (params) => params
    },
    {
        field:   'abgang',
        width: 100,
        headerName: (year) => 'Abgang.',
        valueFormatter: (params) => params
    },
    {
        field:   'vertrag',
        width: 100,
        headerName: (year) => 'Vertrag.',
        valueFormatter: (params) => params
    },


    {
        field:   'wasser',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Wasser VE',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'heizung',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Heizung VE',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'ruecklagen_wodis',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'IH-Rücklage # Wodis'.replace('#', year - 2),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'ruecklagen_abrechnung',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'IH-Rücklage # lt. Abrechnung'.replace('#', year - 2),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'ruecklagen_diff',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Diff. Rücklage',
        valueGetter: (params) => calcDiff(params),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(calcDiff(b)||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zufuehrung_abrechnung',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Zuführung IH-Rücklage lt. Abrechnung',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'entnahme_abrechnung',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Entnahme IH-Rücklage lt. Abrechnung',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zinsen',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Zinsen',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'kapital',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Kapi',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter:  (params) => Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})
    },
    {
        field:   'soli',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Soli',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zufuehrung',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Zuführung',
        valueGetter: (params) => calcZufuehrung(params),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(calcZufuehrung(b)||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'zuefuehrunggesamt',
        width: 100,
        type: 'number',
        align: 'right',
        headerName: (year) => 'Gesamt Zuführung',
        valueGetter: (params) => calcZufuehrungGesamt(params),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(calcZufuehrungGesamt(b)||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'ruecklagen_abrechnung_gesamt',
        width: 100,
        align: 'right',
        type: 'number',
        headerName: (year) => 'IH-Rücklage # lt. Abrechnung'.replace('#', year - 1),
        valueGetter: (params) => calcAbrechnungGesamt(params),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(calcAbrechnungGesamt(b)||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
]


function calcZufuehrungGesamt(params){
    return Number(params['zufuehrung_abrechnung'] || '') +
        Number(params['zinsen'] || '') +
        Number(params['kapital'] || '') +
        Number(params['soli'] || '') +
        Number(params['entnahme_abrechnung'] || '') +
        Number(params['ruecklagen_abrechnung'] || '') -
        Number(params['ruecklagen_wodis'] || '')
}

function calcAbrechnungGesamt(params){
    return Number(params['zufuehrung_abrechnung'] || '') +
        Number(params['zinsen'] || '') +
        Number(params['kapital'] || '') +
        Number(params['soli'] || '') +
        Number(params['entnahme_abrechnung'] || '') +
        Number(params['ruecklagen_abrechnung'] || '')
}

function calcZufuehrung(params){
    return Number(params['zufuehrung_abrechnung'] || '') +
        Number(params['zinsen'] || '') +
        Number(params['kapital'] || '') +
        Number(params['soli'] || '')
}

function calcDiff(params){
    return Number(params['ruecklagen_abrechnung'] || '') - Number(params['ruecklagen_wodis'] || '')
}

function valueNumber(params) {
    return Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function valueNumberFourDigit(params) {
    return Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 4, maximumFractionDigits: 4})
}

export const abrechnung_wohngeld = [
    {
        field: 'beko',
        width: 100,
        valueGetter: (params) => params.beko,
        headerName: (year) => 'Beko-VV',
        valueFormatter: (params) => params
    },
    {
        field: 'sachkonten',
        width: 100,
        autoComplete: true,
        secondField: 'bezeichg',
        headerName: (year) => 'Sachkonten',
        valueFormatter: (params) => params
    },
    {
        field: 'bezeichg',
        width: 200,
        align: 'left',
        headerName: (year) => 'Bezeichnung',
        valueGetter: (params) => params.bezeichg,
        valueFormatter: (params) => params
    },
    {
        field: 'text',
        width: 100,
        align: 'left',
        headerName: (year) => 'Text',
        valueFormatter: (params) => params
    },
    {
        field: 'aufteilung',
        width: 100,
        headerName: (year) => 'Aufteilung',
        valueFormatter: (params) => params
    },
    {
        field: 'kosten_gesamt',
        width: 100,
        align: 'right',
        type: 'number',
        headerName: (year) => 'Kosten Gesamt',
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field: 'anteil',
        width: 100,
        align: 'right',
        headerName: (year) => 'Anteil',
        type: 'number',
        valueGetter: () => '',
        valueComplexGetter: (params, gesamt, part) => Number(params['kosten_gesamt'])*Number(part[(params['aufteilung']||'').toLowerCase() + 'gesamt']||1)/Number(gesamt[(params['aufteilung']||'').toLowerCase() + 'gesamt']||'1'),
        valueFormatter: (params) => valueNumber(params)

    },
    {
        field: 'steuer',
        width: 100,
        type: 'number',
        valueGetter: (params) => params.steuer,
        headerName: (year) => 'Steuer',
        valueFormatter: (params) => params
    },
]
