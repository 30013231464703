import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './Home';
import Secret from '../MainPage/result';
import Pwreset from './Pwreset'
import withAuth from '../auth'
import withStyles from "@material-ui/core/styles/withStyles";
import {appHistory} from "../history"

const useStyles = theme => ({
    content:{
        height: "100%",

    }
});



class App extends Component {

    render() {
        const classes = this.props
        return (
            <Router history={appHistory}>
                <div className={classes.content}>
                    <Switch>
                        <Route path="/login" exact component={Home} />
                        <Route path="/pwreset" exact component={Pwreset} />
                        <Route component={withAuth(Secret)} />
                    </Switch>
                </div>
            </Router>

        );
    }
}

export default withStyles(useStyles)(App)