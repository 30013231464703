import React from 'react'
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete"
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Edit from "./onEdit";
import {uuid} from "uuidv4";
import {Decimal} from "decimal.js"
Decimal.set({ precision:20, rounding:4 })
function isValidDate(s) {
    if ( ! /^\d\d.\d\d.\d\d\d\d$/.test(s) ) {
        return false;
    }
    const parts = s.split('.').map((p) => parseInt(p, 10));
    parts[1] -= 1;
    const d = new Date(parts[2], parts[1], parts[0]);
    return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2];
}

const columnsToFill = ['saldenvortrag', 'diffeur', 'diffdate', 'beschreibung', 'zinssatz']
function sorting(data, year) {
    return data.sort(function (a, b) {
        const aSplit = (a['diffdate'] || '01.01.' + year).split('.')
        const bSplit = (b['diffdate'] || '01.01.' + year).split('.')
        return new Date(aSplit[2], aSplit[1] - 1, aSplit[0]) - new Date(bSplit[2], bSplit[1] - 1, bSplit[0])
    })
}

function calculate(data, year) {
    const len = data.length
    data.forEach((x, i) => {
        if (i === 0) {
            x['verbleiben'] = new Decimal(Number(x.saldenvortrag || 0)).add(Number(x.diffeur || 0))
            if (x.saldenvortrag != '' && x.saldenvortrag != "0") {
                x['zinsZeitraumAb'] = '01.01.' + year
            } else {
                x['zinsZeitraumAb'] = x.diffdate
            }
            if (i < len - 1) {
                let date = data[i + 1].diffdate.split('.')
                date = new Date(date[2], date[1] - 1, date[0] - 1)
                x['zinsZeitraumBis'] = (String(date.getDate()).length === 1 ? '0' : '') + String(date.getDate()) + '.'
                    + (String(date.getMonth() + 1).length === 1 ? '0' : '') + String(date.getMonth() + 1) + '.'
                    + String(date.getFullYear())
            } else {
                x['zinsZeitraumBis'] = '31.12.' + year
            }
        } else {
            x['verbleiben'] = new Decimal(data[i - 1].verbleiben || 0).add(Number(x.diffeur || 0))
            x['zinsZeitraumAb'] = data[i].diffdate
            if (i < len - 1) {
                let date = data[i + 1].diffdate.split('.')
                date = new Date(date[2], date[1] - 1, date[0] - 1)

                x['zinsZeitraumBis'] = (String(date.getDate()).length === 1 ? '0' : '') + String(date.getDate()) + '.'
                    + (String(date.getMonth() + 1).length === 1 ? '0' : '') + String(date.getMonth() + 1) + '.'
                    + String(date.getFullYear())

            } else {
                x['zinsZeitraumBis'] = '31.12.' + year
            }
        }
        const bis = x.zinsZeitraumBis.split('.')
        const ab = x.zinsZeitraumAb.split('.')
        const bisDate = new Date(bis[2], bis[1] - 1, bis[0])
        const abDate = new Date(ab[2], ab[1] - 1, ab[0])


        x['zinstage'] = Math.round((bisDate - abDate) / (1000 * 60 * 60 * 24)) + 1
        const daysInYear = new Decimal(Math.round((new Date(year, 11, 31)  - new Date(year, 0, 1))/ (1000 * 60 * 60 * 24)) + 1)
	const verbleibend = new Decimal(x.verbleiben)
	const days = new Decimal(x.zinstage)
	const zinssatz = new Decimal(x?.zinssatz || 0)
	const value = verbleibend.times(days).times(zinssatz).div(daysInYear).round().div(100)
	console.log(value.eq(value.pow(2)))
	if (value.eq(value.pow(2))){
		x['zinsenZeitraum'] =0 	
	}
	else {

       		 x['zinsenZeitraum'] = value   
	}})
    if (len>0){
	    	
    	data[0].diffdate = data[0].zinsZeitraumAb
    }
    return data
}


const columns = [
    {id: 'action'},
    {id: 'saldenvortrag', type: 'number'},
    {id: 'diffeur', type: 'number'},
    {id: 'diffdate', type: 'date'},
    {id: 'beschreibung', type: 'text'},
    {id: 'verbleiben', type: 'number'},
    {id: 'zinsZeitraumAb'},
    {id: 'zinsZeitraumBis'},
    {id: 'zinstage'},
    {id: 'zinssatz', type: 'number'},
    {id: 'zinsenZeitraum', type: 'number'}]

const zinsData = (id) => {return {
    saldenvortrag: '',
    diffeur: '',
    diffdate: '',
    beschreibung: '',
    verbleiben: '',
    zinsZeitraumAb: '',
    zinsZeitraumBis: '',
    zinstage: '',
    zinssatz: '',
    zinsenZeitraum: '',
    selection_id: id
}}

export default class Zins extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            zinsEdit: {...zinsData(this.props.tableId)},
            zinsAdd: {...zinsData(this.props.tableId)},
            editId: -1,
            backdropOpen: true
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.componentDidMount()
        }
    }


    componentDidMount() {
        const body = {
            id: this.props.tableId,
            type: 'zins'
        }

        fetch('api/data',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
            this.setState({
                ...this.state,
                data: data,
                backdropOpen: false
            })
    })}


    //NOW ZINSSTUFF
    toggleEdit = (i) => {
        this.setState({...this.state, editId: i, zinsEdit: {...this.state.data.filter(x => x.id === i)[0]}})
    }

    handleDataChange = (id, newValue, state) => {
        this.setState({
            ...this.state,
            [state]: {...this.state[state], [id]: newValue}
        })
    }

    handleFinishAdd = () => {
        this.setState(prevState => {
            const add = {...prevState.zinsAdd}
            add.id = uuid()
            if (!isValidDate(add.diffdate)) {
                return {...this.state}
            }
            columns.forEach(x => (x.type === 'number') ? add[x.id] = Number(prevState.zinsAdd[x.id]) : add[x.id] = prevState.zinsAdd[x.id])
            fetch('/api/addChangeZins', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...add,
                    type: 'zins',
                    mandanten: this.props.mandant,
                    tab: this.props.tab,
                    jahr: this.props.year,
		    selection_id: this.props.tableId
                })
            })

            return {...this.state, data: [...this.state.data, {...add}], zinsAdd: {...zinsData(this.props.tableId)}}
        })
    }


    handleFinishChange = () => {
        this.setState(prevState => {
            const add = {...prevState.zinsEdit}
            columns.forEach(x => (x.type === 'number') ? add[x.id] = Number(add[x.id]) : add[x.id] = add[x.id])
            fetch('/api/addChangeZins', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...add,
                    type: 'zins',
                    mandanten: this.props.mandant,
                    tab: this.props.tab,
                    jahr: this.props.year
                })
            })

            const index = prevState.data.findIndex(x => x.id === prevState.editId)
            let data = [...prevState.data]
            data[index] = {...add}
            return {...this.state, data: data, zinsEdit: {...zinsData(this.props.tableId)}, editId: -1}
        })
    }


    handleDataDelete = (i) => {
        fetch('/api/addChangeZins', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: i, mandanten: this.props.mandant, tab: this.props.tab, jahr: this.props.year})
        })

        this.setState(prevState => {
            let data = [...prevState.data]
            const index = prevState.data.findIndex(x => x.id === i)
            data.splice(index, 1)
            return {...this.state, data: data}
        })
    }

    render() {
        const {
            data,
            editId,
            zinsAdd,
            zinsEdit,
            backdropOpen
        } = this.state
        const {
            year,
        } = this.props


        return (
            (!backdropOpen) &&
        <Paper>

            <TableContainer
                style={{maxHeight: 'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right" rowSpan={2}/>
                            <TableCell align="right" rowSpan={2}>
                                <div>
                                    Saldo
                                </div>
                                <div>
                                    EURO
                                </div>
                            </TableCell>
                            <TableCell rowSpan={2} align="right">

                                    <div>
                                        Zu-/Abgang
                                    </div>

                                    <div>
                                        EURO
                                    </div>


                            </TableCell>
                            <TableCell  rowSpan={2} align="right">

                                <div/>
                                <div>
                                    am
                                </div>

                            </TableCell>
                            <TableCell align="right" rowSpan={2}>
                                Beschreibung
                            </TableCell>
                            <TableCell align="right" rowSpan={2}>
                                <div>
                                    Zinsbasis
                                </div>
                                <div>
                                    EURO
                                </div>
                            </TableCell>
                            <TableCell colSpan={1} align="right" rowSpan={2}>
                                von
                            </TableCell>
                            <TableCell colSpan={1} align="right" rowSpan={2}>
                                bis
                            </TableCell>
                            <TableCell align="right" rowSpan={2}>
                                Zinstage
                            </TableCell>
                            <TableCell align="right" rowSpan={2}>
                                Zinssatz
                            </TableCell>
                            <TableCell align="right" rowSpan={2}>
                                <div>
                                    Zinsen
                                </div>
                                <div>
                                    EURO
                                </div>
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {calculate(sorting(data, year), year).map((row, i) => ((row.id === editId) ?
                            <Edit doubleline={false}
                                  toggleEdit={this.toggleEdit}
                                  dataRow={zinsEdit}
                                  columns={columns}
                                  columnsToFill={columnsToFill}
                                  add={'zinsEdit'}
                                  handleZinsDataChange={this.handleDataChange}
                                  handleFinish={this.handleFinishChange}
                            />
                            :
                            <TableRow key={row.id}>
                                {columns.map(cell =>
                                    ((cell.id === 'action') ?
                                        <TableCell align="right">
                                            <IconButton onClick={() => this.toggleEdit(row.id)}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={() => this.handleDataDelete(row.id)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </TableCell>
                                        :
                                        <TableCell align="right">
                                            {(cell.type !== 'number') ?
                                                row[cell.id] :
                                                Number(row[cell.id] || 0).toLocaleString("de-DE", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                        </TableCell>))
                                }
                            </TableRow>))}
                        <Edit doubleline={true}
                              dataRow={zinsAdd}
                              columns={columns}
                              columnsToFill={columnsToFill}
                              add={'zinsAdd'}
                              handleZinsDataChange={this.handleDataChange}
                              toggleEdit={this.toggleEdit}
                              handleFinish={this.handleFinishAdd}
                        />
                        <TableRow key={"Gesamt"}>
                            <TableCell colSpan={1} align="right">
                                Gesamt
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                {(data.map(x => x.diffeur).reduce((a, b) => Number(b || '') + a, 0) + data.map(x => x.zinsenZeitraum).reduce((a, b) => Number(b || '') + a, 0) + Number((data[0]||{saldenvortrag: ''}).saldenvortrag || '')).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </TableCell>
                            <TableCell colSpan={1} align="right">
                                Veränderung {(data.map(x => x.diffeur).reduce((a, b) => Number(b || '') + a, 0)).toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </TableCell>
                            <TableCell colSpan={2} align="right">

                            </TableCell>
                            <TableCell colSpan={3} align="right"/>
                            <TableCell align="right">
                                {data.map(x => x.zinstage).reduce((a, b) => a + b, 0)}
                            </TableCell>
                            <TableCell/>
                            <TableCell align="right">
                                {Number((data.map(x => x.zinsenZeitraum).reduce((a, b) => Decimal(a).add(b), 0)||Decimal(0)).toNearest(0.001, 4)||0).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
        )
    }
}
