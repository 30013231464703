import React from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';




function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


export default function (props) {

    const {open, solution} = props


    return (
        <div>
            <Dialog open={open}
                    maxWidth='md'

                    PaperComponent={PaperComponent}>
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Speichern
                </DialogTitle>
                <DialogContent>
                    Sollen die Änderungen gespeichert werden?
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => solution('else')}>
                        Abbrechen
                    </Button>
                    <Button color="primary" onClick={() => solution('discardChanges')}>
                        Verwerfen
                    </Button>
                    <Button color="primary" onClick={() => solution('save')}>
                        Speichern
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    )


}