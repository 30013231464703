import React from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';

import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    root: {
            margin: theme.spacing(1),
            maxWidth: '25ch',
    },
}));


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function (props) {
    const classes = useStyles()
    const {open, handleAddOpen, dataToAdd, handleDataAddEntry, handleDataAdd, requiredError, disabled} = props


    return (
        <div>
            <Dialog open={open}
                    maxWidth='lg'
                //onClose={handleAddOpen}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            handleDataAdd()
                        }
                    }}
                    PaperComponent={PaperComponent}>
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Tabellen Daten eingeben.
                </DialogTitle>
                <ValidatorForm>
                    <DialogContent >
                        <TextField onChange={handleDataAddEntry}
                                   value={dataToAdd.kreditor}
                                   className={classes.root}
                                   id="kreditor"
                                   autoFocus={true}
                                   label="Kreditor"
                                   error={requiredError.kreditorError}
                        />

                        <TextField onChange={handleDataAddEntry}
                                   value={dataToAdd.leistung}
                                   id="leistung"
                                   className={classes.root}
                                   label="Leistung"
                                   error={requiredError.leistungError}
                        />
                        <NumberFormat
                            {...props}
                            id="zeitraum"
                            label="Zeitraum"
                            value={dataToAdd.zeitraum}
                            className={classes.root}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            error={requiredError.zeitraumError}
                            format="##.##.####-##.##.####"
                            disabled={false}

                        />
                        <TextField onChange={handleDataAddEntry}
                                       value={dataToAdd.barcode}
                                       className={classes.root}
                                       validators={['required', 'matchRegexp:[0-9]{7}']}
                                       errorMessages={['Feld ist notwendig']}
                                       id="barcode"
                                       label="Interne Belegnummer"/>
                        <TextField onChange={handleDataAddEntry}
                                   value={dataToAdd.gegenkonto}
                                   id="gegenkonto"
                                   className={classes.root}
                                   label="Konto"
                                   error={requiredError.gegenkontoError}
                        />
                        <TextField onChange={handleDataAddEntry}
                                       value={dataToAdd.kst}
                                       id="kst"
                                       className={classes.root}
                                       label="KSt"
                                       validators={['required', 'matchRegexp:[0-9]{4,}']}
                                       errorMessages={['Feld ist notwendig']}
                        />
                        <NumberFormat
                            {...props}
                            id="vortrag"
                            label="Vortrag"
                            value={dataToAdd.vortrag}
                            className={classes.root}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.vortrag || false}
                        />
                        <NumberFormat
                            {...props}
                            id="jan"
                            label="Januar"
                            value={dataToAdd.jan}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            className={classes.root}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.jan || false}
                        />
                        <NumberFormat
                            {...props}
                            id="feb"
                            label="Februar"
                            value={dataToAdd.feb}
                            onChange={handleDataAddEntry}
                            className={classes.root}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.feb || false}
                        />
                        <NumberFormat
                            {...props}
                            id="mrz"
                            label="März"
                            value={dataToAdd.mrz}
                            onChange={handleDataAddEntry}
                            className={classes.root}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.mrz || false}
                        />
                        <NumberFormat
                            {...props}
                            id="apr"
                            label="April"
                            value={dataToAdd.apr}
                            onChange={handleDataAddEntry}
                            className={classes.root}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.apr || false}
                        />
                        <NumberFormat
                            {...props}
                            id="mai"
                            label="Mai"
                            className={classes.root}
                            value={dataToAdd.mai}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.mai || false}
                        />
                        <NumberFormat
                            {...props}
                            id="jun"
                            label="Juni"
                            className={classes.root}
                            value={dataToAdd.jun}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.jun || false}
                        />

                        <NumberFormat
                            {...props}
                            id="jul"
                            label="Juli"
                            className={classes.root}
                            value={dataToAdd.jul}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.jul || false}
                        />
                        <NumberFormat
                            {...props}
                            id="aug"
                            label="August"
                            className={classes.root}
                            value={dataToAdd.aug}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.aug || false}
                        />
                        <NumberFormat
                            {...props}
                            id="sep"
                            className={classes.root}
                            label="September"
                            value={dataToAdd.sep}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.sep || false}
                        />
                        <NumberFormat
                            {...props}
                            className={classes.root}
                            id="okt"
                            label="Oktober"
                            value={dataToAdd.okt}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.nov || false}
                        />
                        <NumberFormat
                            {...props}
                            className={classes.root}
                            id="nov"
                            label="November"
                            value={dataToAdd.nov}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.nov || false}
                        />
                        <NumberFormat
                            {...props}
                            className={classes.root}
                            id="dez"
                            label="Dezember"
                            value={dataToAdd.dez}
                            onChange={handleDataAddEntry}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            disabled={disabled.dez || false}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={handleAddOpen}>
                            Abbrechen
                        </Button>
                        <Button color="primary" onClick={handleDataAdd}>
                            Hinzufügen
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </div>
    )


}