import React from "react";

import ReactDataGrid from "react-data-grid"
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import NumberFormatWithZero from './numberFormatWithZero'
import {NumberFormatWithZeroMinus, NumberFormatWithZeroPlus} from './numberFormatWithZeroRed'
import NumberFormat from "./numberFormat";
import NumberFormatInt from "./numberFormatInt";
import AddButton from "../AddButton";


const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '100%',
    },

    addButton: {
        position: 'absolute',
        bottom: 10,
        right: 10,
    },


}))


function width(data, key) {
    if (key === 'gesamt') {
        return 120
    } else {
        const longest = data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
        return Math.max(Math.min(Number((Number(longest || 0).toLocaleString("de-DE", {minimumFractionDigits: 2})).length) * 11, 400), 80)
    }

}


export default function Reuckstellungen(props) {

    const classes = useStyles()
    const {
        data,
        backdropOpen,
        addOpen,
        getCellActions,
        rowGetter,
        write,
        rowRenderer,
        onGridRowsUpdated,
        type
    } = props
    const columns = [
        {key: "events", name: "", frozen: true, resizable: true, width: 190},
        {key: "kreditor", name: "Kreditor", frozen: true, resizable: true},
        {key: "leistung", name: "Leistung", frozen: true, resizable: true, width: 300},
        {key: "zeitraum", name: "Zeitraum", frozen: true, resizable: true},
        {key: "barcode", name: "Interne Belegnummer", frozen: true, resizable: true},
        {key: "gegenkonto", name: "Konto", frozen: true, resizable: true, formatter: NumberFormatInt},
        {key: "kst", name: "KSt", frozen: true, resizable: true, formatter: NumberFormatInt},
        {
            key: "vortrag",
            name: "Vortrag",
            resizable: true,
            frozen: true,
            formatter: NumberFormatWithZero,
            width: width(data, 'vortrag')
        },
        {key: "jan", name: "Jan", resizable: true, formatter: NumberFormat, width: width(data, 'jan')},
        {key: "feb", name: "Feb", resizable: true, formatter: NumberFormat, width: width(data, 'feb')},
        {key: "mrz", name: "Mrz", resizable: true, formatter: NumberFormat, width: width(data, 'mrz')},
        {key: "apr", name: "Apr", resizable: true, formatter: NumberFormat, width: width(data, 'apr')},
        {key: "mai", name: "Mai", resizable: true, formatter: NumberFormat, width: width(data, 'mai')},
        {key: "jun", name: "Jun", resizable: true, formatter: NumberFormat, width: width(data, 'jun')},
        {key: "jul", name: "Jul", resizable: true, formatter: NumberFormat, width: width(data, 'jul')},
        {key: "aug", name: "Aug", resizable: true, formatter: NumberFormat, width: width(data, 'aug')},
        {key: "sep", name: "Sep", resizable: true, formatter: NumberFormat, width: width(data, 'sep')},
        {key: "okt", name: "Okt", resizable: true, formatter: NumberFormat, width: width(data, 'okt')},
        {key: "nov", name: "Nov", resizable: true, formatter: NumberFormat, width: width(data, 'nov')},
        {key: "dez", name: "Dez", resizable: true, formatter: NumberFormat, width: width(data, 'dez')},
        {
            key: "gesamt",
            name: "Gesamt",
            formatter: (type === "arap") ? NumberFormatWithZeroPlus : NumberFormatWithZeroMinus,
            width: width(data, 'gesamt')
        },
    ];


    return (
        <div className={classes.content}>
            <ReactDataGrid
                columns={columns}
                minHeight={'calc(100vh - ' + document.getElementById("SelectTable").clientHeight + 'px - ' + document.getElementById("AppBar").clientHeight + 'px)'}
                rowGetter={rowGetter}
                rowsCount={data.length + 1}
                getCellActions={getCellActions}
                enableCellSelect={true}
                rowRenderer={rowRenderer}
                onGridRowsUpdated={onGridRowsUpdated}
            />


            <Backdrop open={backdropOpen} style={{zIndex: 99}}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <AddButton write={write}
                       addOpen={addOpen}
                       classes={classes}/>


        </div>
    )
}
