
export const typeItems = [
    {
        key: 'rueck',
        name: 'Rückstellung'
    },
    {
        key: 'arap',
        name: 'ARAP'
    },
    {
        key: 'prap',
        name: 'PRAP'
    },
    {
        key: 'inven',
        name: 'Inventur'
    },
    {
        key: 'zins',
        name: 'Zins'
    },
    {
        key: 'wohngeld',
        name: 'Wohngeld'
    },
    {
        key: 'kreditor',
        name: 'Kreditoren'
    },
    {
        key: 'debitor',
        name: 'Debitoren'
    }

]
export const months = [{
    key: "jan",
    name: "Januar"
},
    {
        key: "feb",
        name: "Februar"
    },
    {
        key: "mrz",
        name: "März"
    },
    {
        key: "apr",
        name: "April"
    },
    {
        key: "mai",
        name: "Mai"
    },
    {
        key: "jun",
        name: "Juni"
    },
    {
        key: "jul",
        name: "Juli"
    },
    {
        key: "aug",
        name: "August"
    },
    {
        key: "sep",
        name: "September"
    },
    {
        key: "okt",
        name: "Oktober"
    },
    {
        key: "nov",
        name: "November"
    },
    {
        key: "dez",
        name: "Dezember"
    }]

export const numb = ["vortrag","jan", "feb", "mrz", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "dez"]


export const columnsInventur = [
    {
        key: "bereich",
        name: "Bereich"
    },
    {
        key: "lager",
        name: "Lager"
    },
    {
        key: "lagerartikel",
        name: "Artikel"
    },
    {
        key: "unit",
        name: "Inhalt"
    },
    {
        key: "typ",
        name: "Verpackungseinheit"
    },
    {
        key: "menge",
        name: "Menge"
    },
    {
        key: "ekpreis",
        name: "EkPreis"
    },
    {
        key: "wert",
        name: "Wert"
    },
    {
        key: "pfand",
        name: "Pfand"
    },
    {
        key: "pfandwert",
        name: "Wert"
    }
];

export const columnsWertebericht = [
    {
        key: "name",
        name: "Name"
    },
    {
        key: "konto",
        name: "Konto"
    },
    {
        key: "rg",
        name: "Rg."
    },
    {
        key: "datum",
        name: "Datum",
        align: "center"
    },
    {
        key: "betragBrutto",
        name: "Betrag brutto",
        align: "right"
    },
    {
        key: "ust",
        name: "Ust %",
        align: "right"
    },
    {
        key: "betragNetto",
        name: "Betrag netto",
        align: "right"
    },
    {
        key: "stand",
        name: "aktueller Stand/Bemerkung"
    },
    {
        key: "wbFirst",
        name: "WB %",
        align: "right"
    },
    {
        key: "wbSecond",
        name: "WB %",
        align: "right"
    },
    {
        key: "wbThird",
        name: "WB %",
        align: "right"
    },
    {
        key: "betragFirst",
        name: "Betrag ",
        align: "right"
    },
    {
        key: "buBetrag",
        name: "BuBetrag ",
        align: "right"
    },
];


export const closedFalse = {
    vortrag: false,
    jan: false,
    feb: false,
    mrz: false,
    apr: false,
    mai: false,
    jun: false,
    jul: false,
    aug: false,
    sep: false,
    okt: false,
    nov: false,
    dez: false}




export const columnsRueck = {
        "kreditor": "Kreditor",
        "leistung": "Leistung",
        "zeitraum": "Zeitraum",
        "barcode": "Barcode",
        "gegenkonto": "Konto",
        "kst": "KSt",
        "vortrag": "Vortrag",
        "jan": "Jan",
        "feb": "Feb",
        "mrz": "Mrz",
        "apr": "Apr",
        "mai": "Mai",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Aug",
        "sep":"Sep",
        "okt":"Okt",
        "nov":"Nov",
        "dez":"Dez"
    }
