import React, {Component, Fragment} from 'react'
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {numb} from "../../../../constants"
import Checkbox from "@material-ui/core/Checkbox";
import DeleteDialog from "../Forms/Delete";
import Selection from "./Selection";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tooltip from "@material-ui/core/Tooltip";
import {uuid} from "uuidv4";
import Table from "./Table";


const columns = [
    {key: "events", name: "Aktion", editable: false, width: 100},
    {key: "mandanten", name: "Mandant", editable: true},
    {key: "jahr", name: "Jahr", editable: false},
    {key: "tab", name: "Tabelle", editable: true},
    {key: "vortrag", name: "Vortrag", editable: false, width: 70},
    {key: "jan", name: "Jan", editable: false, width: 50},
    {key: "feb", name: "Feb", editable: false, width: 50},
    {key: "mrz", name: "Mrz", editable: false, width: 50},
    {key: "apr", name: "Apr", editable: false, width: 50},
    {key: "mai", name: "Mai", editable: false, width: 50},
    {key: "jun", name: "Jun", editable: false, width: 50},
    {key: "jul", name: "Jul", editable: false, width: 50},
    {key: "aug", name: "Aug", editable: false, width: 50},
    {key: "sep", name: "Sep", editable: false, width: 50},
    {key: "okt", name: "Okt", editable: false, width: 50},
    {key: "nov", name: "Nov", editable: false, width: 50},
    {key: "dez", name: "Dez", editable: false, width: 50},

];

const columns_without = [
    {key: "events", name: "Aktion", editable: false, width: 100},
    {key: "mandanten", name: "Mandant", editable: true},
    {key: "jahr", name: "Jahr", editable: false},
    {key: "tab", name: "Tabelle", editable: true},
];

//TODO Am umbenennen arbeiten und die Tabellen über Ids ansteuern.
class RenameTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: columns,
            id:'',
            type:'',
            deleteOpen: false,
	    nextYearExists: false
        };
    }

    getCellActions = (column, row) => {
        if (column.key === 'events') {
            return [
                {
                    icon: <Tooltip title="Löschen"><IconButton size="small"><DeleteIcon/></IconButton></Tooltip>,
                    callback: () => this.handleDataDeleteCheck(row.id)
                },
                {
                    icon: <Tooltip title="Nächstes Jahr hinzufügen"><IconButton size="small"><NavigateNextIcon/></IconButton></Tooltip>,
                    callback: () => this.handleNextYear(row)
                }
            ]
        } else if (numb.includes(column.key) && ['rueck', 'arap', 'prap'].includes(row.type)) {
            return [
                {
                    icon: <Checkbox checked={row[column.key]}/>,
                    callback: () => this.handleCloseColumn(row, column.key)}
            ]
        }
    }

    handleNextYear = (row) => {
        const id = uuid()
        fetch('api/nextYear', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mandanten: row.mandanten,
                jahr: row.jahr,
                table: row.tab,
                type: row.type,
                selection_id: row.id,
                selection_id_new: id
            })
        }).then((ret) => {
		if (ret.status === 200) {
                this.setState((prevState) => {
                    if (prevState.data.filter(x => x.tab === row.tab && x.jahr === String(Number(row.jahr) + 1) && x.mandanten === row.mandanten).length === 0) {
                        const dataAll = prevState.dataAll
                        const data = prevState.data

                        let index = dataAll.findIndex(x => x.id === row.id)
                        dataAll.splice(index + 1, 0, {...row, jahr: String(Number(row.jahr) + 1), id: id})
                        index = prevState.data.findIndex(x => x.id === row.id)
                        data.splice(index + 1, 0, {...row, jahr: String(Number(row.jahr) + 1), id: id})
                        return {...prevState, dataAll: dataAll, data: data}
                    }})
            }
            else {
                this.setState((prevState) => {
                    return {...prevState, nextYearExists: true}
                })
            }
      })
    }

    //TODO Wohngeld checken
    //TODO Tabelle Löschen, dann auch aus Auswahl löschen. Und wenn neuer Mandant, Auswahl anzeigen
    //TODO INVENTUR ändern
    
    handleCloseColumn = (row, key) => {
        this.setState((prevState) => {
                const data = [...prevState.data]
                const index = data.findIndex((x) => x.id === row.id)
                data[index] = {...row, [key]: !row[key]}
                return {...prevState, data}
            }, () => {
                const body = {
                    id: row.id,
                    closed: !row[key],
                    key: key
                }

                fetch('/api/closeColumn', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }).then()
            }
        )
    }

    handleNextYearExists = () => {
        this.setState({...this.state, nextYearExists: false})
    }

    onGridRowsUpdated = ({fromRowData, fromRow, updated}) => {
        const {mandanten, tab, id} = fromRowData
        fetch('api/rename', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mandantenOld: mandanten,
                tableOld: tab,
                mandanten: updated.mandanten || mandanten,
                table: updated.tab || tab,
                id: id
            })
        }).then(() =>
            this.setState((prevState) => {
                const rows = [...prevState.data]
                rows[fromRow] = {...rows[fromRow], ...updated}
                return {...prevState, data: rows}
            })
        )
    };

    handleDataDeleteCheck = (id) => {
        this.setState({...this.state, id:id, deleteOpen: true})
    }
    
    handleDataDelete = () => {
        const id = this.state.id
        fetch('api/rename', {
            method: 'DELETE', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: id})
        }).then(() => this.setState(prevState => {
            const dataAll = [...prevState.dataAll].filter(x => x.id !== id)
            const data = [...prevState.data].filter(x => x.id !== id)
            return {...prevState, dataAll: [...dataAll], data: data}
        }, () => {
            this.toggleDeleteCheck()
        }))
    }

    toggleDeleteCheck = () => {
        this.setState({...this.state, id:'', deleteOpen:false})
    }

    changeType = (value) => {
        this.setState((prevState) => {
            const data = prevState.dataAll.filter((x) => x.type === value.key)
            var new_col = []
            if (['arap', 'prap', 'rueck'].includes(value.key)){
                new_col = columns
            }
            else {
                new_col = columns_without
            }
            return {...this.state, type:value, data: data, columns: new_col}
        })
    }

    componentDidMount() {
        fetch('api/rename').then(result => result.json())
            .then(data => this.setState({...this.state, dataAll: data}))
    }
    rowCount = () => {
        return this.state.data.length
    }


    render() {
        const {
            data,
            deleteOpen,
           type,
		nextYearExists
        } = this.state

        return (
            <Fragment>
                <Selection
                    type={type}
                    changeType={this.changeType}
                />
                {(['arap', 'prap', 'rueck'].includes(type.key)) &&
                    <Table
                        data={data}
                        columns={columns}
                        getCellActions={this.getCellActions}
                        onGridRowsUpdated={this.onGridRowsUpdated}
                    />
                }
                {(['zins', 'inven', 'wohngeld', 'kreditor', 'debitor'].includes(type.key)) &&
                    <Table
                        data={data}
                        columns={columns_without}
                        getCellActions={this.getCellActions}
                        onGridRowsUpdated={this.onGridRowsUpdated}
                    />
                }
                <DeleteDialog
                    handleDelete={this.handleDataDelete}
                    handleDeleteToggle={this.toggleDeleteCheck}
                    typ={'Durch bestätigen wird der Eintrag unwiederruflich gelöscht!'}
                    deleteOpen={deleteOpen}
                />
		 <DeleteDialog
                    handleDelete={this.handleNextYearExists}
                    handleDeleteToggle={this.handleNextYearExists}
                    typ={'Nächstes Jahr existiert schon und muss erst gelöscht werden!'}
                    deleteOpen={nextYearExists}
                />
            </Fragment>
        )
    }
}

export default RenameTable
