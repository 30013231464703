import React from "react";

export default function (props) {
    const {left, value, width} = props
    return (
        <div className="react-grid-Cell" style={{
            position: 'absolute',
            width: width,
            height: 35,
            left: left

        }}>
            <div className="react-grid-Cell__value">
                <div style={{
                    marginLeft: 0,
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)'
                }}>
                                <span>
                                    <div style={{textAlign: 'right', fontWeight: "bold"}}>
                                        {value}
                                    </div>
                                </span>
                </div>
            </div>
        </div>
    )

}
