import React from "react";

export default function (props) {
    const {width, scroll, text} = props
    return (
        <div className="react-grid-Cell react-grid-Cell--frozen"
             style={{
                 position: 'absolute',
                 zIndex: 98,
                 width: width + scroll,
                 marginLeft: 0,
                 transform: 'translate3d(0px, 0px, 0px)',
                 height: 35
             }}>
            <div className="react-grid-Cell__value">
                <div style={{marginLeft: 0, position: 'relative', top: '50%', transform: 'translateY(-50%)'}}>
                    <span>
                        <div style={{textAlign: 'right', fontWeight: "bold"}}>
                            {text}
                        </div>
                    </span>
                </div>
            </div>
        </div>

    )
}
