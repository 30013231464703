import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import React, {Fragment} from "react";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {columnsRueck} from "../../../../../../constants";

export default function HistoryDialog(props) {
    const {open, history, close} = props

    return (
        <Dialog open={open} onClose={close} minWidth={"lg"}>
            <DialogTitle>
                Historie
            </DialogTitle>
            <DialogContent>

                    {history.map(x =>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={(x.operation === 'UPDATE')  && <ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                            >
                                <Grid container xs={12} spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">
                                            {x.tstamp.split('T')[0].split('-')[2] + '.' + x.tstamp.split('T')[0].split('-')[1] + '.' + x.tstamp.split('T')[0].split('-')[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">
                                            {x.who}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">
                                            {(x.operation === 'UPDATE') ? 'Update' : 'Erstellt'}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </AccordionSummary>
                            {(x.operation === 'UPDATE') &&
                            <AccordionDetails>
                                <Grid container spacing={3} >
                                    <Grid container xs={12} spacing={3} >
                                        <Fragment>
                                            <Grid item xs={4} >
                                                <Typography variant="subtitle1" style={{borderBottom: '1px solid #ccc'}}>
                                                    Feld
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} >
                                                <Typography variant="subtitle1" style={{borderBottom: '1px solid #ccc'}}>
                                                    Alt
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} >
                                                <Typography variant="subtitle1" style={{borderBottom: '1px solid #ccc'}}>
                                                    Neu
                                                </Typography>
                                            </Grid>
                                        </Fragment>

                                    </Grid>
                                    {Object.keys(x.val).map(y => (
                                        columnsRueck[y] &&
                                        <Fragment>
                                            <Grid container xs={12} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1">
                                                        {columnsRueck[y]}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1">
                                                        {x.old_val[y]}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1">
                                                        {x.new_val[y]}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Fragment>

                                        )
                                    )}
                                </Grid>



                            </AccordionDetails> }
                        </Accordion>
                    )}


            </DialogContent>
        </Dialog>
    )
}
