import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, {Fragment} from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";


export default function SumGetter(props) {
    const {columns, data, gesamt, toggleEdit,} = props
    return (
        <Fragment>
            <TableRow height={30}/>
            <TableRow>
                <TableCell />
                {
                    columns.map((entry) =>
                        <TableCell
                            align={entry.align || "center"}
                        >
                            {(entry.secondSumGetter  && entry.field !== 'gesamt_ve' && entry.field !== 'wp_diff_') && entry.valueFormatter(entry.secondSumGetter(entry.sumGetter(data, entry.field), gesamt[entry.field + 'gesamt']))}
                            {(entry.field === 'gesamt_ve') && entry.valueFormatter('')}
                            {(entry.field === 'wp_diff_') && entry.valueFormatter('')}
                        </TableCell>
                    )
                }
            </TableRow>
            <TableRow>
                <TableCell style={{width:150}}>
                    <IconButton size={'small'} onClick={() => toggleEdit('editGesamt')}>
                        <EditIcon fontSize={'small'} />
                    </IconButton>
                </TableCell>
                {
                    columns.map((entry) =>
                        <TableCell
                            align={entry.align || "center"}
                        >
                            {(entry.secondSumGetter && entry.field !== 'gesamt_ve' && entry.field !== 'wp_diff_') &&  entry.valueFormatter(gesamt[entry.field + 'gesamt'])}
                            {(entry.field === 'gesamt_ve') && entry.valueFormatter(entry.sumGetter(data, entry.field))}
                            {(entry.field === 'wp_diff_') && entry.valueFormatter(entry.sumGetter(data, entry.field))}
                        </TableCell>
                    )
                }
            </TableRow>
    </Fragment>
    )

}