import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Client/Login/login'
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('93u0wy/gruen');

LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
    name: 'James Morrison',
});

setupLogRocketReact(LogRocket);

ReactDOM.render(<App className="body html"/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
