import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import AddTableDialog from "./AddTableDialog";
import SelectTable from "./TableSelection";
import Zins from "./Zinstable/Zinstable";
import AddTableRestriction from "./AddTableRestriction";
import Inventur from "./Inventur/Inventur"
import withStyles from "@material-ui/core/styles/withStyles";
import FileSaver from 'file-saver'
import Wohngeld from "./Wohngeld/Wohngeld"
import {uuid} from "uuidv4";
import Rueck from "./Rueckstellungen/Rueck";
import {months} from "../../../../constants";
import Kreditor from "./Debitoren/Kreditoren";
import Wirtschaftsplan from "./Wirtschaftsplan/Wirtschaftsplan";
import * as XLSX from 'xlsx';

const useStyles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
})

const a = {
    "Mdt.":{key: "mdt"},
    "WIE":{key: "wie"},
    "Obj.":{key: "obj"},
    "VE":{key: "ve"},
    "VE-Bezeichnung":{key: "ve_bezeichnung"},
    "Etage":{key: "etage"},
    "Lage":{key: "lage"},
    "MIBU":{key: "mibu"},
    "Zugang":{key: "zugang"},
    "Abgang":{key: "abgang"},
    "Vertrag":{key: "vertrag"},
    "WF":{key: "wf", type: 'number'},
    "HF":{key: "hf", type: 'number'},
    "V1":{key: "v1", type: 'number'},
    "V2":{key: "v2", type: 'number'},
    "V3":{key: "v3", type: 'number'},
    "WE":{key: "we", type: 'number'},
    "TE":{key: "te", type: 'number'},
    "Gesamt VE":{key: "gesamt_ve", type: 'number'},
    "WP-Diff.":{key: "wp_diff_", type: 'number'}
}


class Content extends Component {
    constructor(props) {
        super(props);
        this.zinsTabelle = React.createRef();
        this.state = {
            restrictions: [],
            admin: false,
            data: [],
            mandant: "",
            kreditor:null,
            kreditorItems:[],
            year: "",
            table: "",
            yearItems: [],
            tableItems: [],
            changeData: false,
            write: false,
            tableAdd: false,
            tabNew: '',
            typeNew: '',
            mandantNew: '',
            yearNew: '',
            tableType: 0,
            tableDialogError: false,
            addTableRestOpen: false,
            users: [],
            usersNewMandant: [],
            rowToDelete: null,
            kontoOptions: [],
            csvOpen: false,
            month: '',
            update:0,
            kreditorSelected: false,
            kreditorNew: ''

        }
    }

    handleMandantChange = (index) => {
        fetch('api/tables', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({mandanten: this.state.restrictions[index]?.id})
        }).then(response => response.json()).then(data => {
            this.setState({
                ...this.state,
                mandant: index,
                yearItems: data,
                tableItems: [],
                year: "",
                table: "",
                tableType: 0,
                kreditorSelected: false
            })
        })


    }

    handleYearChange = (year) => {
        fetch('api/tables', {
            method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({mandanten: this.state.restrictions[this.state.mandant].id, year: year})
        }).then(response => response.json()).then(data => {
            let contains = data.map(x => x.tab).indexOf(this.state.tableItems[this.state.table]?.tab)
            if (contains > -1 ) {
                this.setState({
                    ...this.state,
                    year: year,
                    tableItems: data,
                    kreditorSelected: false
                },() => {
                    this.handleTableChange(contains, 'table', '0', 0) })
            }
            else {
                this.setState({
                    ...this.state,
                    year: year,
                    tableItems: data,
                    tableType: 0,
                    table: "",
                    kreditorSelected: false
                })
            }

        })
    }

    handleTableChange = (value, select, tableType, id) => {
        if (['wohngeld','wirtschaftsplan'].includes(this.state.tableItems[value]?.type)) {
            const body = {tab: this.state.tableItems[value].tab, mandanten: this.state.restrictions[this.state.mandant].id, year: this.state.year}
            fetch('/api/tables', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(body)}).then(data => data.json()).then(data => this.setState({...this.state, table: value,
                tableId: this.state.tableItems[value].id,
                tableType: this.state.tableItems[value].type,
                kreditorItems: data,
                kreditorSelected: false
            }))
        }
        else if (select === 'table') {
            if (this.state.tableItems[value]?.type === 'inven') {
                this.setState({
                    ...this.state,
                    table: value,
                    tableId: this.state.tableItems[value].id,
                    tableType: this.state.tableItems[value].type,
                    kreditorSelected: false
                })
            } else {
                this.setState({
                    ...this.state,
                    table: value,
                    backdropOpen: true,
                    month: '',
                    tableId: this.state.tableItems[value]?.id,
                    tableType: this.state.tableItems[value]?.type,
                    kreditorSelected: false
                })
            }
        } else {
            this.setState({
                backdropOpen: true,
                month: value,
                kreditorSelected: false
            })
        }
    }

    handleKreditorChange = (value) => {
        this.setState({...this.state, kreditor: value, tableId: this.state.kreditorItems[value]?.id, kreditorSelected: true})
    }

    processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length === headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] === '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] === '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j] && a[headers[j]]) {
                        if (a[headers[j]].type ==='number') {
                            d = d.replace(',','.')
                        }
                        obj[a[headers[j]].key] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    console.log(obj)
                    fetch('/api/addChangeKreditor', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            id: 0,
                            selection_id: this.state.tableId,
                            data: obj,
                        })
                    }).then(() => setTimeout(() => console.log(1),100))
                }
            }
        }
        setTimeout(() => this.setState({...this.state, update:this.state.update +1}), 900)
    }

    // handle file upload
    handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            this.processData(data);
        };
        reader.readAsBinaryString(file);
    }



    csvToggle = () => {
        this.setState({
            ...this.state,
            csvOpen: !this.state.csvOpen,
        })
    }

    downloadExcel = () => {
        const body = {
            mandant: this.state.restrictions[this.state.mandant].mandanten,
            year: this.state.year,
            type: this.state.tableType,
            tab: this.state.tableItems[this.state.table].tab,
            selectionId: this.state.tableId
        }
        fetch('/api/getExcel', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify(body)
        }).then((data) =>
            data.blob()
        ).then((res) => {
            const url = window.URL.createObjectURL(res)
            FileSaver.saveAs(url, this.state.restrictions[this.state.mandant].mandanten + '_' + this.state.tableItems[this.state.table].tab + '_' + this.state.year + '.xlsx')
        })
    }


    handleTableAdd = () => {
        this.setState({
            ...this.state,
            tableAdd: !this.state.tableAdd,
            mandantNew: this.state.restrictions[this.state.mandant]?.mandanten,
            yearNew: this.state.year,
            tabNew: this.state.tableItems[this.state.table]?.tab
        })
    }

    handleTableChangeField = (event) => {
        this.setState({...this.state, typeNew: event.target.value})
    }




    handleTableAddField = (event) => {
        this.setState({...this.state, [event.target.id]: event.target.value})
    }

    handleCommitTable = () => {
        if (this.state.mandantNew !== '' && this.state.typeNew !== '' && this.state.tabNew !== '' && this.state.yearNew !== '') {
            const id = uuid()
            fetch('api/checkNewMandant', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({mandant: String(this.state.mandantNew), tab: this.state.tabNew, jahr: this.state.yearNew, type: this.state.typeNew, kreditor: this.state.kreditorNew, id:id})
            }).then(res => {
                if(res.status === 301){
                    alert('Die Tabelle existiert schon')
                }
                else{
                res.json().then(data => {
                    const {user} = data
                    if (user.length === 0){
                        window.location.reload(false);
                    }
                    else {
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                data: [],
                                tableAdd: false,
                                tableDialogError: false,
                                users: user,
                                addTableRestOpen: 0 !== user.length
                            }
                        })
                    }

            })}
            })
        } else {
            this.setState({...this.state, tableDialogError: true})
        }
    }

    handleRestrictionCheck = (id) => () => {
        this.setState(prevState => {
            const checked = [...prevState.usersNewMandant]
            if (checked.includes(id)) {
                checked.splice(checked.indexOf(id), 1)
            } else {
                checked.push(id)
            }
            return {...this.state, usersNewMandant: [...checked]}
        })
    }

    handleRestrictionToggle = () => {
        this.setState({...this.state, addTableRestOpen: false})
    }


    handleRestrictionCommit = () => {
        fetch('/api/newMandant', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({mandant: this.state.restrictions[this.state.mandant].id, id: this.state.usersNewMandant})
        }).then(() => window.location.reload(false))
    }

    componentDidMount() {
        fetch('api/restrictions').then(response => response.json()).then(data =>
            this.setState({...this.state, restrictions: data || []})
        )

        fetch('/checkWrite').then(res => {
            if (res.status === 200) {
                this.setState({...this.state, write: true});
            } else {
                throw res.error;
            }
        }).catch(err => {
            console.log(err)
            this.setState({...this.state, write: false})
        })

        fetch('/checkAdmin').then(res => {
            if (res.status === 200) {
                this.setState({...this.state, admin: true});
            } else {

                throw res.error;
            }
        }).catch(err => {
            console.log(err)
            this.setState({...this.state, admin: false})
        })
    }









    render() {
        const {classes} = this.props
        const {
            restrictions,
            mandant,
            year,
            yearItems,
            table,
            tableItems,
            write,
            tableAdd,
            tabNew,
            yearNew,
            mandantNew,
            tableType,
            typeNew,
            tableDialogError,
            admin,
            users,
            addTableRestOpen,
            usersNewMandant,
            csvOpen,
            month,
            tableId,
            update,
            kreditor,
            kreditorItems,
            kreditorSelected
        } = this.state


        return (
            <main className={classes.content}>
                <Paper variant="outlined" square className={classes.paper}>
                    <SelectTable key={'SelectTable'}
                                 className={classes.select}
                                 handleMandantChange={this.handleMandantChange}
                                 handleTableChange={this.handleTableChange}
                                 handleYearChange={this.handleYearChange}
                                 handleKreditorChange={this.handleKreditorChange}
                                 mandant={mandant}
                                 kreditor={kreditor}
                                 kreditorItems={kreditorItems}
                                 kreditorSelected={kreditorSelected}
                                 year={year}
                                 yearItems={yearItems}
                                 table={table}
                                 tableItems={tableItems}
                                 admin={admin}
                                 downloadCSV={this.csvToggle}
                                 restrictions={restrictions}
                                 downloadExcel={this.downloadExcel}
                                 handleTableAdd={this.handleTableAdd}
                                 tableType={tableType}
                                 month={month}
                                 printTable={''}
                                 uploadCsv={this.handleFileUpload}
                    />

                    {(tableType === 'zins') &&
                        <Zins
                            mandant={mandant}
                            tab={table.tab}
                            tableId={tableId}
                            year={year}
                        />
                        }
                    {(tableType === 'rueck' || tableType === 'arap' || tableType === 'prap') &&
                        <Rueck
                            mandant={mandant}
                            tab={tableItems[table].tab}
                            year={year}
                            tableType={tableType}
                            write={write}
                            admin={admin}
                            csvOpen={csvOpen}
                            csvToggle={this.csvToggle}
                            tableId={tableId}
                        />
                    }

                    {
                        (tableType === 'inven' && month !== "") &&
                        <Inventur
                            mandant={restrictions[mandant].id}
                            tabelle={table.tab}
                            type={tableType}
                            month={months[month]?.key}
                            year={year}
                            tableId={tableId}
                        />
                    }
                    {(tableType  === 'wohngeld') && kreditorSelected &&
                    <Wohngeld
                        jahr={year}
                        mandanten={mandant}
                        write={write}
                        tab={table.tab}
                        tableId={tableId}
                        update={update}
                    />

                    }
                    {(tableType  === 'kreditor' || tableType  === 'debitor') &&
                    <Kreditor
                        jahr={year}
                        mandanten={mandant}
                        write={write}
                        type={tableType}
                        tableId={tableId}
                    />}
                    {(tableType  === 'wirtschaftsplan') && kreditorSelected &&
                        <Wirtschaftsplan
                        jahr={year}
                        mandanten={mandant}
                        write={write}
                        tableId={tableId}
                        />
                    }

                    <AddTableDialog
                        open={tableAdd}
                        mandant={mandantNew}
                        year={yearNew}
                        tab={tabNew}
                        type={typeNew}
                        handleTableAddField={this.handleTableAddField}
                        handleCommit={this.handleCommitTable}
                        handleToggle={this.handleTableAdd}
                        handleTableChangeField={this.handleTableChangeField}
                        error={tableDialogError}
                    />

                    <AddTableRestriction
                        open={addTableRestOpen}
                        users={users}
                        checked={usersNewMandant}
                        handleToggleCheckbox={this.handleRestrictionCheck}
                        handleToggle={this.handleRestrictionToggle}
                        handleCommit={this.handleRestrictionCommit}
                    />
                </Paper>
            </main>
        )
    }
}


export default withStyles(useStyles)(Content)

