export const kreditorCol = [
    {
        field:   'kreditor',
        width: 300,
        align: 'left',
        headerName: (year) => 'Kreditor',
        valueFormatter: (params) => params
    },
    {
        field:   'konto',
        width: 150,
        headerName: (year) => 'Konto',
        valueFormatter: (params) => params
    },
    {
        field:   'vortrag',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => 'Vortrag',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['vortrag']||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'year',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => year,
        sumGetter: (params, field) =>  params.reduce((a,b) => a + Number(b[field]||''), 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['year']||'') + Number(b['vortrag']||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'gesamt',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => 'Gesamt.',
        valueGetter: (params) => Number(params['vortrag']||'') + Number(params['year']||''),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b['vortrag']||'') + Number(b['year']||'') , 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['vortrag']||'') + Number(b['year']||'') , 0),
        valueFormatter: (params) => valueNumber(params)
    },
]

export const debitorCol = [
    {
        field:   'kreditor',
        width: 300,
        align: 'left',
        headerName: (year) => 'Debitor',
        valueFormatter: (params) => params
    },
    {
        field:   'konto',
        width: 150,
        headerName: (year) => 'Konto',
        valueFormatter: (params) => params
    },
    {
        field:   'vortrag',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => 'Vortrag',
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b[field]||''), 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['vortrag']||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'year',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => year,
        sumGetter: (params, field) =>  params.reduce((a,b) => a + Number(b[field]||''), 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['year']||'') + Number(b['vortrag']||''), 0),
        valueFormatter: (params) => valueNumber(params)
    },
    {
        field:   'gesamt',
        width: 150,
        align: 'right',
        type: 'number',
        headerName: (year) => 'Gesamt.',
        valueGetter: (params) => Number(params['vortrag']||'') + Number(params['year']||''),
        sumGetter: (params, field) => params.reduce((a,b) => a + Number(b['vortrag']||'') + Number(b['year']||'') , 0),
        thirdSumGetter: (params) => params.reduce((a,b) => a + Number(b['vortrag']||'') + Number(b['year']||'') , 0),
        valueFormatter: (params) => valueNumber(params)
    },
]


function valueNumber(params) {
    return Number(params || 0).toLocaleString("de-DE", {minimumFractionDigits: 2, maxFractionDigits: 2})
}
