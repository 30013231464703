import Reuckstellungen from "./reuckstellungen";
import AddDialog from "./AddDialog";
import DeleteDialog from "../../Forms/Delete";
import React, {Component, Fragment} from "react";
import MergedCells from "./mergedCells";
import SingleFixedCell from "./CellsFixed";
import SingleCells from "./Cells";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {closedFalse} from "../../../../../constants";
import withStyles from "@material-ui/core/styles/withStyles";
import CSVDialog from "../CSVDialog";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import HistoryDialog from "./History/HistoryDialog";

const useStyles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
})


const initError = {kreditorError: false, leistungError: false, zeitraumError: false, gegenkontoError: false}

const initialDataToAdd = {
    id: '',
    kreditor: '',
    leistung: '',
    zeitraum: '',
    barcode: '',
    gegenkonto: '',
    kst: '',
    vortrag: '',
    jan: '',
    feb: '',
    mrz: '',
    apr: '',
    mai: '',
    jun: '',
    jul: '',
    aug: '',
    sep: '',
    okt: '',
    nov: '',
    dez: '',
    mandanten: '',
    jahr: '',
    tab: ''
}
const merge = [
    "events",
    "kreditor",
    "leistung",
    "zeitraum",
    "barcode",
    "gegenkonto",
    "kst",

]
const cells = [
    "vortrag",
    "jan",
    "feb",
    "mrz",
    "apr",
    "mai",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dez"
]




class Rueck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            backdropOpen: false,
            addOpen: false,
            dataToAdd: {...initialDataToAdd},
            dataToAddError: {...initError},
            rueckDataDeleteCheck: false,
            kreditorOptions: [],
            disabled: {...closedFalse},
            csvData: {konto: "", month: "", zeileMax: "", zeileMin: "", text: ""},
            width: 0,
            value: 0,
            left: 0,
            openHistory: false,
            history: []
        }
    }

    componentDidMount() {
        this.getData().then(data => {
                data.forEach((x, i) => x.events = i + 1)
                this.setState({
                        ...this.state,
                        data: data,
                        backdropOpen: false,
                    }, () => {

                        fetch('/api/disabled', {
                            method: 'POST', headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                mandanten: this.props.mandant,
                                tab: this.props.tab,
                                year: this.props.year,
                                type: this.props.tableType,
                                selection_id: this.props.tableId
                            })
                        })
                            .then(response => response.json()).then(res => this.setState({
                            ...this.state,
                            disabled: {...res}
                        }))

                    }
                )
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableId !== this.props.tableId) {
            this.componentDidMount()
        }
    }

    async getData() {
        const body = {
            mandant: this.props.mandant,
            year: this.props.year,
            tab: this.props.tab,
            type: this.props.tableType,
            id: this.props.tableId
        }

        const response = await fetch('api/data',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
        return response.json()
    }

    async postData() {
        const body = {...this.state.dataToAdd, type: this.props.tableType, selection_id:this.props.tableId}
        const response = await fetch('api/addData', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        return response.json()
    }

    dataSum = (key) => {
        return this.state.data.reduce((a, b) => a + (Number(b[key]) || 0), 0)
    }


    rowRenderer = ({renderBaseRow, ...props}) => {
        if (props.idx < this.state.data.length) {
            return <div>{renderBaseRow(props)}</div>
        }
        if (props.idx === this.state.data.length) {
            const mergeIndex = merge.map(itemArray => props.columns.findIndex(itemObj => itemObj.key === itemArray))
            const width = [mergeIndex.reduce((a, b) => props.columns[b].width + a, 0)]
            const left = [0]
            const value = [0]
            cells.forEach((x, i) => {
                width.push(props.columns[props.columns.findIndex(itemObj => itemObj.key === x)].width || 0)
                left.push(left[i] + width[i])
                value.push(this.dataSum(x))
            })
            return (
                <div>
                    <div style={{height: 35}} class="react-grid-Row react-grid-Row--even">
                        <MergedCells width={width[0]} scroll={props.scrollLeft} text={"Gesamt"}/>
                        <SingleFixedCell value={value[1].toLocaleString("de-DE", {minimumFractionDigits: 2})}
                                         width={width[1]}
                                         left={left[1]}
                                         scroll={props.scrollLeft}
                        />

                        {cells.map((x, i) =>
                            (
                                <SingleCells
                                    value={value[i + 1].toLocaleString("de-DE", {minimumFractionDigits: 2})}
                                    width={width[i + 1]}
                                    left={left[i + 1]}/>
                            )
                        )}
                        <SingleCells
                            value={value.reduce((a, b) => a + b, 0).toLocaleString("de-DE", {minimumFractionDigits: 2})}
                            width={props.columns[props.columns.findIndex(itemObj => itemObj.key === "gesamt")].width}
                            left={left[left.length - 1] + width[width.length - 1]}/>

                    </div>

                    <div style={{height: 35}} className="react-grid-Row react-grid-Row--even">
                        <MergedCells width={width[0]} scroll={props.scrollLeft} text={"Gesamt Fortlaufend"}/>
                        <SingleFixedCell value={value[1].toLocaleString("de-DE", {minimumFractionDigits: 2})}
                                         width={width[1]}
                                         left={left[1]}
                                         scroll={props.scrollLeft}
                        />
                        {cells.map((x, i) =>
                            (
                                <SingleCells
                                    value={(value.slice(0, i + 2).reduce((a, b) => a + b, 0)).toLocaleString("de-DE", {minimumFractionDigits: 2})}
                                    width={width[i + 1]}
                                    left={left[i + 1]}/>
                            )
                        )}
                        <SingleCells
                            value={(value.reduce((a, b) => a + b, 0)).toLocaleString("de-DE", {minimumFractionDigits: 2})}
                            width={props.columns[props.columns.findIndex(itemObj => itemObj.key === "gesamt")].width}
                            left={left[left.length - 1] + width[width.length - 1]}/>
                    </div>
                </div>

            )
        }
        return null

    }

    handleAddOpen = () => {
        initialDataToAdd.mandanten = this.props.mandant
        initialDataToAdd.jahr = this.props.year
        initialDataToAdd.tab = this.props.tab
        this.setState({
            ...this.state,
            addOpen: !this.state.addOpen,
            dataToAdd: initialDataToAdd,
            dataToAddError: {
                kreditorError: false,
                leistungError: false,
                zeitraumError: false,
                gegenkontoError: false
            }
        })
    }
    handleCopyRow = (row) => {
        delete row.id
        const body = {...row, type: this.props.tableType, selection_id: this.props.tableId}
        fetch('api/addData', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(res => res.json()).then(response => {
            if (!this.state.changeData) {
                this.setState((prevState) => {
                    const data = prevState.data
                    data.splice(parseInt(row.events), 0, {...row, id: response.id})
                    data.forEach((x, i) => x.events = String(i + 1))
                    return {...prevState, data: [...data]}
                })
            }
        })
    }

    getRowHistory = (id) => {
        const body = {id}
        fetch('api/getHistory', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(res => res.json()).then(response => {
            this.setState({...this.state, history: response, openHistory: true})
        })
    }

    getCellActions = (column, row) => {
        if (this.props.write && column.key === 'events') {
            return [(this.props.admin &&
                {
                    icon: <Tooltip title="History"><IconButton size="small"><HistoryIcon/></IconButton></Tooltip>,
                    callback: () => this.getRowHistory(row.id)
                }),
                {
                    icon: <Tooltip title="Kopieren"><IconButton size="small"><FileCopyIcon/></IconButton></Tooltip>,
                    callback: () => this.handleCopyRow(row)
                },
                {
                    icon: <Tooltip title="Editieren"><IconButton size="small"><EditIcon/></IconButton></Tooltip>,
                    callback: () => this.handleDataChange(row)
                },
                {
                    icon: <Tooltip title="Löschen"><IconButton size="small"><DeleteIcon/></IconButton></Tooltip>,
                    callback: () => this.handleDataDeleteCheck(row)
                }
            ]
        }
    }

    handleDataChange = (row) => {
        const rowToUpdate = {...row}
        rowToUpdate.mandanten = this.props.mandant
        rowToUpdate.jahr = this.props.year
        rowToUpdate.tab = this.props.tab
        cells.map((x) => rowToUpdate[x] = rowToUpdate[x].replace('\.', ','))
        this.setState({...this.state, dataToAdd: {...rowToUpdate}, addOpen: !this.state.addOpen, changeData: true})
    }

    rowGetter = (index) => {
        const data = this.state.data
        if (index !== -1 && index < data.length) {
            data[index].gesamt = (Math.round(100 * (Number(data[index].vortrag)
                + Number(data[index].jan)
                + Number(data[index].feb)
                + Number(data[index].mrz)
                + Number(data[index].apr)
                + Number(data[index].mai)
                + Number(data[index].jun)
                + Number(data[index].jul)
                + Number(data[index].aug)
                + Number(data[index].sep)
                + Number(data[index].okt)
                + Number(data[index].nov)
                + Number(data[index].dez)) + 0.03) / 100).toString()

            return data[index]
        }
        return []
    }
    onGridRowsUpdated = ({fromRowData, updated}) => {
        const newUpdated = {[Object.keys(updated)[0]]: Object.values(updated)[0].replace(',', '.')}
        this.setState({
            ...this.state,
            dataToAdd: {
                ...fromRowData, ...newUpdated,
                mandanten: this.props.mandant,
                jahr: this.props.year,
                tab: this.props.tab,
                selection_id: this.props.tableId
            },
            changeData: true
        }, () => {
            this.handleDataAdd()
        })


    }
    handleDataAddEntry = (event) => {
        const id = event.target.id
        let updatedData = {...this.state.dataToAdd}
        updatedData[id] = event.target.value
        this.setState({...this.state, dataToAdd: updatedData})
    }

    handleDataAdd = async () => {
        this.setState({...this.state, dataToAddError: {...initError}})
        if (this.state.dataToAdd.kreditor !== '' && this.state.dataToAdd.leistung !== '' && this.state.dataToAdd.zeitraum !== '' && this.state.dataToAdd.gegenkonto !== '') {
            await this.setState((prevState) => {
                const update = prevState.dataToAdd
                cells.map((x) => update[x] = update[x].replace(/\./g, '').replace(',', '.'))
                return {...prevState, dataToAdd: {...update}}
            })
            const body = {...this.state.dataToAdd, type: this.props.tableType, selection_id: this.props.tableId}
            fetch('api/addData', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(res => res.json()).then(response => {
                if (!this.state.changeData) {
                    this.setState({
                        ...this.state,
                        dataToAdd: {...initialDataToAdd},
                        data: [...this.state.data, {
                            ...this.state.dataToAdd,
                            id: response.id,
                            events: this.state.data.length + 1
                        }],
                    })
                    document.getElementById('kreditor').focus()
                } else {
                    const updateData = [...this.state.data]
                    updateData[updateData.findIndex(x => x.id === this.state.dataToAdd.id)] = {
                        ...this.state.dataToAdd,
                        mandanten: this.props.mandant,
                        jahr: this.props.year,
                        tab: this.state.tab
                    }
                    this.setState({
                        ...this.state,
                        dataToAdd: {...initialDataToAdd},
                        data: [...updateData],
                        addOpen: false,
                        changeData: false,
                    })

                }
            })
        } else {

            this.setState({
                ...this.state,
                dataToAddError: {
                    kreditorError: this.state.dataToAdd.kreditor === '',
                    leistungError: this.state.dataToAdd.leistung === '',
                    zeitraumError: this.state.dataToAdd.zeitraum === '',
                    gegenkontoError: this.state.dataToAdd.gegenkonto === ''
                }
            })
            document.getElementById('kreditor').focus()
        }
    }

    handleDataAddEntryAutoComplete = (event, newValue) => {
        let updatedData = {...this.state.dataToAdd}
        updatedData["kreditor"] = newValue
        this.setState({...this.state, dataToAdd: updatedData})
    }


    handleDataDeleteCheck = (row) => {
        this.setState({...this.state, rowToDelete: row.id, rueckDataDeleteCheck: true})
    }

    handleDataDeleteCheckToggle = () => {
        this.setState({...this.state, rowToDelete: null, rueckDataDeleteCheck: false})
    }

    handleDataDelete = () => {
        this.deleteData(this.state.rowToDelete).then()
        const deletedData = [...this.state.data]
        deletedData.splice(deletedData.findIndex(x => x.id === this.state.rowToDelete), 1)
        this.setState({
            ...this.state,
            rowToDelete: null,
            rueckDataDeleteCheck: false,
            data: [...deletedData]
        })
    }

    async deleteData(id) {
        await fetch('api/deleteData', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: id})
        })
    }

    handleCSVChange = (value, field) => {
        this.setState({...this.state, csvData: {...this.state.csvData, [field]: value}})
    }

    handleCloseHistory = () => {
        this.setState({...this.state, openHistory: false, history: []})
    }

    render() {
        const {
            write,
            tableType,
            csvOpen,
            csvToggle,
            year
        } = this.props

        const {
            backdropOpen,
            addOpen,
            dataToAdd,
            dataToAddError,
            rueckDataDeleteCheck,
            kreditorOptions,
            disabled,
            data,
            csvData,
            openHistory,
            history
        } = this.state



        return(
            <Fragment>
                <Reuckstellungen
                    data={data}
                    backdropOpen={backdropOpen}
                    addOpen={this.handleAddOpen}
                    getCellActions={this.getCellActions}
                    rowGetter={this.rowGetter}
                    write={write}
                    rowRenderer={this.rowRenderer}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    type={tableType}
                />

                <AddDialog open={addOpen}
                           handleAddOpen={this.handleAddOpen}
                           requiredError={dataToAddError}
                           dataToAdd={dataToAdd}
                           handleDataAddEntry={this.handleDataAddEntry}
                           handleDataAdd={this.handleDataAdd}
                           stammdaten={kreditorOptions}
                           handleControlledAdd={this.handleDataAddEntryAutoComplete}
                           disabled={disabled}

                />

                <DeleteDialog deleteOpen={rueckDataDeleteCheck}
                              handleDelete={this.handleDataDelete}
                              handleDeleteToggle={this.handleDataDeleteCheckToggle}
                              typ={'Durch bestätigen wird der Eintrag unwiederruflich gelöscht!'}/>
                <CSVDialog
                    open={csvOpen}
                    handleToggle={csvToggle}
                    csv={csvData}
                    handleChange={this.handleCSVChange}
                    year={year}
                    tableData={data}
                    type={tableType}
                />

                <HistoryDialog
                    open={openHistory}
                    history={history}
                    close={this.handleCloseHistory}
                />
            </Fragment>
        )
    }
}

export default withStyles(useStyles)(Rueck)