import React from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ValidatorForm} from 'react-material-ui-form-validator';

import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import NumberFormat from 'react-number-format';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


export default function (props) {
    const classes = useStyles()
    const {open, handleAddOpen, dataToAdd, handleDataAddEntry, handleDataAdd, options, loading, loadData, optionsfields} = props


    return (
        <div>
            <Dialog open={open}
                    maxWidth='md'
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            handleDataAdd()
                        }
                    }}
                    PaperComponent={PaperComponent}>
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Neuen Lagerartikel eingeben.
                </DialogTitle>
                <ValidatorForm>
                    <DialogContent className={classes.root}>
                        <div style={{display: "flex"}}>
                            <Autocomplete
                                freeSolo
                                autoFocus={true}
                                autoHighlight={true}

                                loading={loading.lagerbeschreibung}
                                onOpen={() => loadData('lagerbeschreibung')}
                                id="lagerbeschreibung"
                                onInputChange={(event, value) => handleDataAddEntry('lagerbeschreibung', value)}
                                value={dataToAdd.lagerbeschreibung || ''}
                                options={Object.keys(options) || ['']}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Kategorie"

                                    />
                                )}
                            />
                            <Autocomplete
                                freeSolo
                                id="bereich"
                                autoHighlight={true}

                                loading={loading.bereich}
                                onOpen={() => loadData('bereich')}
                                onInputChange={(event, value) => handleDataAddEntry('bereich', value)}
                                value={dataToAdd.bereich || ''}
                                options={optionsfields.bereich}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Bereich"

                                    />
                                )}
                            />
                            <Autocomplete
                                freeSolo
                                id="lager"
                                autoHighlight={true}

                                loading={loading.lager}
                                onOpen={() => loadData('lager')}
                                onInputChange={(event, value) => handleDataAddEntry('lager', value)}
                                value={dataToAdd.lager || ''}
                                options={optionsfields.lager}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Lager"

                                    />
                                )}
                            />
                            <Autocomplete
                                freeSolo
                                id="lagerartikel"
                                autoHighlight={true}

                                loading={loading.lagerartikel}
                                onInputChange={(event, value) => handleDataAddEntry('lagerartikel', value)}
                                value={dataToAdd.lagerartikel || ''}
                                options={optionsfields.lagerartikel}
                                onOpen={() => loadData('lagerartikel')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Artikel"

                                    />
                                )}
                            />


                        </div>

                        <NumberFormat
                            {...props}
                            id="size"
                            label="Inhalt"
                            value={dataToAdd.size || ''}
                            onValueChange={(value) => handleDataAddEntry('size', value.value)}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                        />

                        <TextField onChange={(event) => handleDataAddEntry('einheit', event.target.value)}
                                   value={dataToAdd.einheit || ''}
                                   id="einheit"
                                   label="Verpackungseinheit"
                        />

                        <NumberFormat
                            {...props}
                            id="ekpreis"
                            label="EkPreis"
                            isNumericString={true}
                            value={dataToAdd.ekpreis || ''}
                            onValueChange={(value) => handleDataAddEntry('ekpreis', value.value)}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                        />

                        <NumberFormat
                            {...props}
                            id="pfand"
                            label="Pfand"
                            isNumericString={true}
                            value={dataToAdd.pfand || ''}
                            onValueChange={(value) => handleDataAddEntry('pfand', value.value)}
                            customInput={TextField}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={handleAddOpen}>
                            Abbrechen
                        </Button>
                        <Button color="primary" onClick={handleDataAdd}>
                            Hinzufügen
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </div>
    )


}