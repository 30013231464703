import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";


export default function ThirdSumRow(props) {
    const {columns, data} = props
    return (
        <TableRow>
            <TableCell>
                Verbleiben
            </TableCell>
            {
                columns.map((entry) =>
                    <TableCell
                        align={entry.align || "center"}
                    >
                        {(entry.thirdSumGetter)? entry.valueFormatter(entry.thirdSumGetter(data)) : ''}
                    </TableCell>
                )
            }
        </TableRow>
    )

}