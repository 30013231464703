import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import MaskedInput from "react-text-mask";
import NumberFormat from "react-number-format";


function TextMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /2/, /0/, /[0-4]/, /[0-9]/]}
            placeholderChar={' '}
            showMask
        />
    );
}

export default function Edit(props) {
    const {doubleline, columnsToFill, columns, toggleEdit, dataRow, handleZinsDataChange, handleFinish, add} = props
    return (<TableRow>
        {columns.map(cell => (
                (columnsToFill.includes(cell.id)) ?
                    (cell.id === 'diffdate') ?
                        <TableCell align="right" style={(doubleline) ? {borderBottom: "double"} : {}}>
                            <TextField
                                id={cell.id}
                                style={{width: 80}}
                                value={dataRow[cell.id]}
                                onChange={(event) => handleZinsDataChange(cell.id, event.target.value, add)}
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}
                            />
                        </TableCell>
                        :
                        <TableCell align="right" style={(doubleline) ? {borderBottom: "double"} : {}}>
                            {(cell.id === 'beschreibung') ?
                                <TextField
                                    id={cell.id}
                                    style={{width: 80}}
                                    value={dataRow[cell.id]}
                                    onChange={(event) => handleZinsDataChange(cell.id, event.target.value, add)}
                                />
                                :
                                <NumberFormat
                                    {...props}
                                    style={{width: 80}}
                                    id={cell.id}
                                    onValueChange={(value) => handleZinsDataChange(cell.id, value.value, add)}
                                    value={dataRow[cell.id] || ''}
                                    customInput={TextField}
                                    thousandSeparator={'.'}
                                    isNumericString={true}
                                    decimalSeparator={','}
                                />}

                        </TableCell> :
                    (cell.id === 'action') ?
                        <TableCell align="right" style={(doubleline) ? {borderBottom: "double"} : {}}>
                            <IconButton onClick={handleFinish}>
                                <CheckIcon/>
                            </IconButton>
                            <IconButton onClick={() => toggleEdit(-1)}>
                                <ClearIcon/>
                            </IconButton>
                        </TableCell>
                        :

                            (cell.id === 'zinsenZeitraum' || cell.id === 'verbleiben') ?
                                <TableCell align="right" style={(doubleline) ? {borderBottom: "double"} : {}}>
                                    {(dataRow[cell.id] || 0).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </TableCell>
                            :
                                <TableCell style={(doubleline) ? {borderBottom: "double"} : {}}>
                                    {dataRow[cell.id]}
                                </TableCell>


            )
        )}
    </TableRow>)
}
