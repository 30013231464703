import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

export default function Moved(props) {
    const { moved, check } = props
    return (
        <Dialog open={moved}>
            <DialogTitle>
                Stop
            </DialogTitle>
            <DialogContent>
                Hier gehts nicht mehr weiter. Gehe bitte auf https://gruen.adlatus-kg.de. Und einmal kontrollieren ob alles passt. Falls nicht Johannes anrufen. :)
                Zum Testen werde ich hier nach wie vor Sachen bereitstellen. Allerdings werden die Daten nicht langfristig gespeichert!!!
            </DialogContent>
            <DialogActions>
                <IconButton  onClick={() => check()}>
                    <CloseIcon/>
                </IconButton>


            </DialogActions>
        </Dialog>
    )
}
