import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
    textfield: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    textfieldbig: {
        margin: theme.spacing(1),
        minWidth: 450
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 200,
        marginTop: theme.spacing(3)
    }

}));

export default function (props) {
    const classes = useStyles()
    const {open, mandant,kreditor, year, tab, handleTableAddField, handleCommit, handleToggle, type, handleTableChangeField, error} = props
    return (<div>
        <Dialog open={open} onClose={handleToggle} onKeyPress={(event) => {
            if (event.key === 'Enter') {
                handleCommit()
            }
        }}>
            <DialogTitle>
                Tabelle
            </DialogTitle>
            <DialogContent>
                Nachfolgend die Daten für die neue Tabelle eingeben
                <br/>
                <TextField
                    className={classes.textfield}
                    onChange={handleTableAddField}
                    error={mandant === '' && error}
                    helperText={(mandant === '' && error) ? "Muss ausgefüllt sein" : ''}
                    value={mandant}
                    id="mandantNew"
                    label="Mandant"
                />
                <TextField
                    className={classes.textfield}
                    onChange={handleTableAddField}
                    error={year === '' && error}
                    helperText={(year === '' && error) ? "Muss ausgefüllt sein" : ''}
                    value={year}
                    id="yearNew"
                    label="Jahr"
                />
                <TextField
                    className={classes.textfield}
                    onChange={handleTableAddField}
                    error={tab === '' && error}
                    helperText={(tab === '' && error) ? "Muss ausgefüllt sein" : ''}
                    value={tab}
                    id="tabNew"
                    label="Tabelle"
                />
                <Select className={classes.select}
                        error={type === '' && error}
                        value={type}
                        id="typeNew"
                        onChange={handleTableChangeField}>
                    <MenuItem value="zins">
                        Zinsen
                    </MenuItem>
                    <MenuItem value="rueck">
                        Rückstellung
                    </MenuItem>
                    <MenuItem value="arap">
                        ARAP
                    </MenuItem>
                    <MenuItem value="prap">
                        PRAP
                    </MenuItem>
                    <MenuItem value="inven">
                        Inventur
                    </MenuItem>
                    <MenuItem value="wohngeld">
                        Wohngeld
                    </MenuItem>
                    <MenuItem value="kreditor">
                        debitorische Kreditoren
                    </MenuItem>
                    <MenuItem value="debitor">
                        kreditorische Debitoren
                    </MenuItem>
                    <MenuItem value="wirtschaftsplan">
                        Wirtschaftsplan
                    </MenuItem>
                    <MenuItem value="">

                    </MenuItem>
                </Select>

                {(type === 'wirtschaftsplan' || type === 'wohngeld') &&
                <TextField
                    className={classes.textfieldbig}
                    onChange={handleTableAddField}
                    value={kreditor}
                    id="kreditorNew"
                    label="Kreditor"
                />}

            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleToggle}>
                    Abbrechen
                </Button>
                <Button color="primary" onClick={handleCommit}>
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
}