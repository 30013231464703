import React, {Component} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormDialog from "./restrictionDialog/restrictionDialog";
import TableBody from "@material-ui/core/TableBody";
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "../Forms/Delete";

const columns = [
    {key: "deletereset", name: "", editable: false},
    {key: "surename", name: "Vorname", editable: false},
    {key: "lastname", name: "Nachname", editable: false},
    {key: "tables", name: "Tabelle", editable: false},
    {key: "write", name: "Bearbeiten", editable: false},
    {key: "admin", name: "Admin", editable: false}
];

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}


class Restriction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            poss: [],
            checked: [],
            rest: [],
            id: '',
            surename: '',
            lastname: '',
            deleteOpen: false

        };
    }


    componentDidMount() {
        fetch('api/users').then(response => response.json()).then(data => {
                this.setState({...this.state, data: data || []})
            }
        )
    }

    handleDeleteUser = (row) => {
        this.setState({...this.state, surename: row.surename, lastname: row.lastname, id: row.id, deleteOpen: true})
    }

    handleDeleteToggle = () => {
        this.setState({...this.state, surename: '', lastname: '', id: '', deleteOpen: false})
    }

    handleDeleteFinally = () => {
        fetch('api/userDelete', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: this.state.id})
        }).then(() => {
            this.setState((prevState) => {
                const data = prevState.data.filter((x) => x.id !== this.state.id)
                return {...prevState, data: data, surename: '', lastname: '', id: '', deleteOpen: false}
            })
        })
    }

    resetPasswort = (id) => {
        fetch('api/pwReset', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({id: id})
        }).then()
    }


    handleRestrictionToggle = (line) => {
        this.setState({
            ...this.state,
            id: this.state.data[line].id,
            open: !this.state.open,
            poss: this.state.data[line].poss,
            rest: this.state.data[line].restriction
        })
    }

    handleRestrictionClose = () => {
        this.setState({...this.state, open: !this.state.open})
    }

    handleToggle = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({...this.state, checked: newChecked})
    };

    handleAllRight = () => {
        this.setState({...this.state, poss: [], rest: this.state.rest.concat(this.state.poss)})
    };

    handleCheckedRight = () => {
        const possChecked = intersection(this.state.checked, this.state.poss)
        this.setState({
            ...this.state,
            poss: not(this.state.poss, possChecked),
            rest: this.state.rest.concat(possChecked),
            checked: not(this.state.checked, possChecked)
        })
    };

    handleAllLeft = () => {
        this.setState({...this.state, poss: this.state.poss.concat(this.state.rest), rest: []})
    }

    handleCheckedLeft = () => {
        const restChecked = intersection(this.state.checked, this.state.rest);
        this.setState({
            ...this.state,
            poss: this.state.poss.concat(restChecked),
            rest: not(this.state.rest, restChecked),
            checked: not(this.state.checked, restChecked)
        })
    }

    handleSubmit = () => {
        const write = this.state.data.find(x => x.id === this.state.id).write
        const admin = this.state.data.find(x => x.id === this.state.id).admin

        fetch('api/users', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: this.state.id, rest: this.state.poss.map(x => x.id), write: write, admin: admin})
        }).then()
        const index = this.state.data.findIndex(x => x.id === this.state.id)
        const line = {...this.state.data[index], poss: this.state.poss, test: this.state.rest.join(',')}
        let data = this.state.data
        data[index] = line
        this.setState({...this.state, open: !this.state.open, data: data})
    }

    handleWriteChange = (event) => {
        const id = event.currentTarget.id
        const line = this.state.data.findIndex(x => x.id === id)
        const write = !this.state.data[line].write
        const updatedLine = {...this.state.data[line], write: write}
        let data = this.state.data
        data[line] = updatedLine
        fetch('api/users', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                rest: this.state.rest.map(x => x.id),
                write: write,
                admin: this.state.data[line].admin
            })
        }).then()

        this.setState({...this.state, data: data})
    }

    handleAdminChange = (event) => {
        const id = event.currentTarget.id
        const line = this.state.data.findIndex(x => x.id === id)
        const admin = !this.state.data[line].admin
        const updatedLine = {...this.state.data[line], admin: admin}
        let data = this.state.data
        data[line] = updatedLine
        fetch('api/users', {
            method: 'POST', headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                rest: this.state.rest.map(x => x.id),
                write: this.state.data[line].write,
                admin: admin
            })
        }).then()

        this.setState({...this.state, data: data})
    }


    render() {
        const {open, data, rest, poss, checked, surename, lastname, deleteOpen} = this.state
        return (
            <div>
                <FormDialog open={open}
                            handleClose={this.handleRestrictionClose}
                            poss={poss}
                            rest={rest}
                            handleToggle={this.handleToggle}
                            checked={checked}
                            handleCheckedRight={this.handleCheckedRight}
                            handleAllRight={this.handleAllRight}
                            handleCheckedLeft={this.handleCheckedLeft}
                            handleAllLeft={this.handleAllLeft}
                            restChecked={intersection(this.state.checked, this.state.rest).length}
                            possChecked={intersection(this.state.checked, this.state.poss).length}
                            handleSubmit={this.handleSubmit}
                />

                <DeleteDialog
                    typ={'Durch bestätigen wird der Benutzer ' + surename + ' ' + lastname + ' unwiederruflich gelöscht!'}
                    deleteOpen={deleteOpen}
                    handleDelete={this.handleDeleteFinally}
                    handleDeleteToggle={this.handleDeleteToggle}
                />


                <TableContainer component={Paper}
                                style={{maxHeight: 'calc(100vh - ' + document.getElementById("AppBar").clientHeight + 'px)'}}>
                    <Table stickyHeader size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((x,index) => (
                                    <TableCell key={index} style={{fontWeight: 'bold'}}>{x.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <Tooltip title="Löschen">
                                            <IconButton onClick={() => this.handleDeleteUser(row)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Passwortreset">
                                            <IconButton onClick={() => this.resetPasswort(row.id)}>
                                                <VpnKeyIcon/>

                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{row.surename}</TableCell>
                                    <TableCell>{row.lastname}</TableCell>
                                    <TableCell id={i}>
                                        <IconButton onClick={() => this.handleRestrictionToggle(i)}>
                                            <SettingsIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            id={row.id}
                                            checked={row.write}
                                            onChange={this.handleWriteChange}
                                            name="checkedA"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            id={row.id}
                                            checked={row.admin}
                                            onChange={this.handleAdminChange}
                                            name="checkedA"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

export default Restriction

