import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioButtons from "./TransferText";

export default function FormDialog(props) {
    const {
        open,
        handleClose,
        poss,
        rest,
        handleToggle,
        checked,
        handleAllRight,
        handleCheckedRight,
        handleAllLeft,
        handleCheckedLeft,
        possChecked,
        restChecked,
        handleSubmit
    } = props


    return (
        <div>
            <Dialog maxWidth='xl' open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">User Berechtigungen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Durch auswählen wird dem jeweilgen Benutzer die Berechtigung zum lesen oder schreiben in der
                        Tabelle gegeben.
                    </DialogContentText>

                    <RadioButtons poss={poss}
                                  rest={rest}
                                  handleToggle={handleToggle}
                                  checked={checked}
                                  handleAllRight={handleAllRight}
                                  handleCheckedRight={handleCheckedRight}
                                  handleAllLeft={handleAllLeft}
                                  handleCheckedLeft={handleCheckedLeft}
                                  restChecked={restChecked}
                                  possChecked={possChecked}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Ändern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}