import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {CSVLink} from "react-csv";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel} from "@material-ui/core";

const separator = ';'
const headers = [
    {key: 'konto', label: 'Konto'},
    {key: 'gegenkonto', label: 'Gegenkonto'},
    {key: 'kst', label: 'Kostenstelle'},
    {key: 'kostentraeger', label: 'Kostenträger'},
    {key: 'periode', label: 'Periode'},
    {key: 'belegdatum', label: 'Belegdatum'},
    {key: 'rgnr', label: 'RG-Nr.'},
    {key: 'belegnr', label: 'Beleg-Nr.'},
    {key: 'buchungstext', label: 'Buchungstext'},
    {key: 'vortrag', label: 'Saldenvortrag'},
    {key: 'waehrung', label: 'Währung'},
    {key: 'betragsoll', label: 'Betrag Soll'},
    {key: 'betrag', label: 'Betrag Haben'},
    {key: 'steuer', label: 'Steuerschlüssel'},
    {key: 'steuerbetrag', label: 'Steuerbetrag Soll'},
    {key: 'steuerbetraghaben', label: 'Steuerbetrag Haben'},
    {key: 'waehrungskurs', label: 'Währungskurs'},
    {key: 'op', label: 'OP'},
    {key: 'tz', label: 'TZ'},
]

const months = [
    {id: "vortrag", text: "Vortrag"},
    {id: "jan", text: "Januar"},
    {id: "feb", text: "Februar"},
    {id: "mrz", text: "März"},
    {id: "apr", text: "April"},
    {id: "mai", text: "Mai"},
    {id: "jun", text: "Juni"},
    {id: "jul", text: "Juli"},
    {id: "aug", text: "August"},
    {id: "sep", text: "September"},
    {id: "okt", text: "Oktober"},
    {id: "nov", text: "November"},
    {id: "dez", text: "Dezember"},
]

const useStyles = makeStyles((theme) => ({
    textfield: {
        margin: theme.spacing(1),
        minWidth: 250
    },
    fullWidthText: {
        minWidth: 500,
        margin: theme.spacing(1)
    }

}));

export default function (props) {
    const classes = useStyles()
    const {handleToggle, open, csv, handleChange, tableData, year, type} = props
    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    return (
        <Dialog
            open={open}
            onClose={handleToggle}
        >
            <DialogTitle>
                CSV Download
            </DialogTitle>
            <DialogContent>
                <TextField
                    className={classes.textfield}
                    onChange={(event) => handleChange(event.target.value, 'konto')}
                    value={csv?.konto}
                    id="konto"
                    autoFocus={true}
                    label="Konto"
                />
                <FormControl className={classes.textfield}>
                    <InputLabel>Monat</InputLabel>
                    <Select
                            value={csv?.month}
                            id="month"
                            label="Monat"
                            onChange={(event) => handleChange(event.target.value, 'month')}
                            error={error}
                    >
                        {months.map((x) => <MenuItem value={x.id}>
                            {x.text}
                        </MenuItem>)}
                    </Select>
                </FormControl>


                <TextField
                    className={classes.textfield}
                    onChange={(event) => handleChange(event.target.value, 'zeileMin')}
                    value={csv?.zeileMin}
                    id="zeileMin"
                    label="Von"
                    helperText={'Wenn leer, wird die erste Zeile genommen'}
                />
                <TextField
                    className={classes.textfield}
                    onChange={(event) => handleChange(event.target.value, 'zeileMax')}
                    value={csv?.zeileMax}
                    id="zeileMax"
                    label="Bis"
                    helperText={'Wenn leer, wird die letzte Zeile genommen'}
                />
                <TextField
                    className={classes.fullWidthText}
                    onChange={(event) => handleChange(event.target.value, 'text')}
                    value={csv?.text}
                    id="text"
                    label="Buchungstext"
                    helperText={'Wird im Buchungstext vor Kredior Leistung und Zeitraum gesetzt'}
                />

            </DialogContent>
            <DialogActions>

                <Button color="primary" onClick={handleToggle}>
                    Abbrechen
                </Button>
                <CSVLink data={data}
                         separator={separator}
                         headers={headers}
                         filename={csv?.konto + "_" + csv?.month + "_" + year + ".csv"}
                         style={{display: 'none'}}
                         id="csv"
                         enclosingCharacter={``}
                />
                <Button color="primary" onClick={async () => {
                    if (csv.month) {
                        const lowerBound = csv?.zeileMin ? Number(csv?.zeileMin) - 1 : 0
                        const upperBound = csv?.zeileMax ? Number(csv?.zeileMax) + 1 : tableData.length + 1
                        const ids = tableData.filter(x => upperBound > x.events && x.events > lowerBound).map(x => x.id)
                        const res = await fetch('/api/getCSV', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({konto: csv.konto, id: ids, month: csv.month, year: year, type: type, text: csv.text})
                        })
                        const result = await res.json()
                        await setData(result)
                        document.getElementById("csv").click()
                        handleToggle()
                    } else {
                        setError(true)
                    }
                }}>
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    )
}