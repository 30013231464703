import React, {Component} from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import './login.css'
import logo from "../../bilder/logo_adlatus.png"
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Moved from "./Moved"
import {Paper} from "@material-ui/core";

const useStyles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",

    },

    button: {
        margin: theme.spacing(2)
    }
});


class Home extends Component {
    state = this.getInitState();
    getInitState() {
        const userInfo = { username: '',
                           password: '',
                            firma: '',
                            email: '',
                            name: '',
                            vorname: '',
                            open: false,
                            moved: false

        }
        return userInfo


    }

    handleChange = name => ({ target: { value }}) => this.setState({
        [name]: value
    })

    handleLogin = (event) => {
        event.preventDefault();
        fetch('/api/authenticate', {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.props.history.push('/');
                } else if (res.status === 401) {
                    alert('User existiert nicht')
                } else if (res.status === 500) {
                    alert('Falsches Passwort')
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch(err => {
                console.log(err)
                alert('Error logging in. please try again');
            });
    }

    handleToggleRegister = () => {
        this.setState({
        ...this.state,
        open: !this.state.open}
    )}

    handleRegister = (event) => {
        event.preventDefault();

        fetch('/api/me', {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                alert('Welcome to the club');
                this.handleToggleRegister()
            }
        })

    }

    check = () => {
        this.setState({...this.state, moved: false})
    }


    render() {
        const { classes } = this.props
        const { open, moved } = this.state
        return (
            <Paper className={classes.root} >
                <Grid    direction="column"
                      justify="center"
                      alignItems="center" container spacing={2}>
                    <Grid  item>
                        <img src={logo} alt={'logo'} />
                    </Grid>
                    <Grid  item>
                        <TextField  id='username' variant='filled' label='Username' onChange={this.handleChange("username")}/>
                    </Grid>
                    <Grid  item>
                        <TextField id='Password' variant='filled' label='Passwort'  onChange={this.handleChange("password")} type="password" autoComplete="current-password"/>
                    </Grid>
                    <Grid  item>

                        <Button className={classes.button} id='register' variant='contained' color='primary' onClick={this.handleToggleRegister}>Registrieren</Button>

                        <Button id='login' variant='contained' color='primary' onClick={this.handleLogin}>Login</Button>

                    </Grid>
                </Grid>




                <Dialog open={open} onClose={this.handleToggleRegister}>
                    <DialogTitle>
                        Registrierung
                    </DialogTitle>
                    <DialogContent>
                        Bitte das nachfolgende Formular ausfüllen
                    </DialogContent>
                    <form className='centering'>
                        <TextField className={classes.textField} id='name' label='Name' onChange={this.handleChange('name')} />
                        <TextField className={classes.textField} id='firma' label='Firma' onChange={this.handleChange('firma')} />
                        <TextField className={classes.textField} id='vorname' label='Vorname' onChange={this.handleChange('vorname')} />
                        <TextField className={classes.textField} id='username' label='Username' onChange={this.handleChange('username')} />
                        <TextField  className={classes.textField} id='email' label='email' onChange={this.handleChange('email')}/>
                        <TextField  className={classes.textField} id='password' label='Passwort' onChange={this.handleChange('password')} />
                        <Button color="primary"
                                className={classes.textField}
                                variant="contained"
                                onClick={this.handleRegister}
                        >Registrieren</Button>
                    </form>
                </Dialog>
                <Moved
                    check={this.check}
                    moved={moved}
                />
            </Paper>
        );
    }
}

export default withStyles(useStyles)(Home)