import React, {Fragment} from 'react'

import Table from '@material-ui/core/Table'
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SumRow from "./SumRow";
import EditDialog from "./EditDialog";
import EditHeaderDialog from "./EditHeaderDialog"
import SecondSumRow from "./SecondSumRow";
import ThirdSumRow from "./ThirdSumRow";



export default class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }




    render() {

        const { maxHeight,
		headerData, editHeader, editableHeader, columns, jahr, columnsGesamt, data, underEdit, id,handleValueChange, sumWord,autoCompleteValue, editGesamt, rowData, edit, dataGesamt, dataPart, autoComplete, thirdSum} = this.props
        return (
            <Fragment >
            <TableContainer style={{width: 'calc(100vw - ' + document.getElementById("Sidebar").clientWidth + 'px)', maxHeight:(maxHeight||'100vh')}}>
                <Table stickyHeader size={'small'} style={{ width: columns.reduce((a,b) => a + b.width, 0) + 140 , margin: 'auto'}}>
                    <TableHead>
                        <TableRow>
                            {editableHeader ?
                                <TableCell style={{width:70}} >
                                    <IconButton size={'small'} onClick={() => this.props.toggleEditGesamt('editHeader')}>
                                        <EditIcon fontSize={'small'} />
                                    </IconButton>
                                </TableCell>
                                :
                                <TableCell style={{width:70}} />}
                            {columns.map((x) =>
                                <TableCell style={{width: x.width}} align={x.align || "center"}>
                                    {x.headerName(jahr)}
                                    <br />
                                    {(x.secondHeaderName) && x.secondHeaderName(headerData[x.field])}
                                </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) =>
                                <TableRow>
                                    <TableCell style={{width:150}}>
                                        <IconButton size={'small'} onClick={() => this.props.toggleEdit(row.id, row)}>
                                            <EditIcon fontSize={'small'} />
                                        </IconButton>
                                        <IconButton size={'small'} onClick={() => this.props.handleDataDelete(row.id)}>
                                            <DeleteIcon fontSize={'small'}/>
                                        </IconButton>
                                    </TableCell>

                                    {
                                        columns.map((entry) => <TableCell
                                            align={entry.align || "center"}
                                        > {(entry.valueGetter && !entry.valueComplexGetter) && entry.valueFormatter(entry.valueGetter(row))}
                                            {(entry.valueComplexGetter) && entry.valueFormatter(entry.valueComplexGetter(row, dataGesamt, dataPart))}
                                            {(!entry.valueGetter && !entry.valueComplexGetter) && entry.valueFormatter(row[entry.field])} </TableCell>)
                                    }
                                </TableRow>
                        )}
                        {(columns.filter((x) => x.sumGetter).length > 0) &&
                            <SumRow
                                data={data}
                                columns={columns}
                                word={sumWord}
                            />}
                        {(columns.filter((x) => x.secondSumGetter).length > 0) &&
                        <SecondSumRow
                            data={data}
                            columns={columns}
                            gesamt={rowData}
                            toggleEdit={this.props.toggleEditGesamt}
                        />}
                        {thirdSum &&
                        <ThirdSumRow
                            data={data}
                            columns={columns}
                        />}


                    </TableBody>
                </Table>
            </TableContainer>
            <EditDialog
                params={columns}
                row={underEdit[id]}
                id={id}
                gesamt={dataGesamt}
                part={dataPart}
                handleSubmit={this.props.submitChange}
                handleClear={this.props.clearChanges}
                handleChange={this.props.handleChanges}
                edit={edit}
                year={jahr}
                autoComplete={autoComplete}
                autoCompleteValue={autoCompleteValue}
                handleValueChange={handleValueChange}
            />
            <EditDialog
                params={columnsGesamt}
                row={rowData}
                id={2}
                handleSubmit={this.props.submitChange}
                handleClear={this.props.clearChanges}
                handleChange={this.props.handleChanges}
                edit={editGesamt}
                year={jahr}
                autoComplete={autoComplete}
                autoCompleteValue={autoCompleteValue}
                handleValueChange={handleValueChange}
            />
            <EditHeaderDialog
                params={columns}
                row={headerData}
                edit={editHeader}
                id={1}
                handleSubmit={this.props.submitChange}
                handleClear={this.props.clearChanges}
                handleChange={this.props.handleChanges}
            />
            </Fragment>
        );
    }
}
